import React, { useState } from 'react';
import { Collection24 } from '@sothebys/sterling/icons';
import style from './index.module.css';

type Props = {
  title: string;
  openByDefault?: boolean;
  id: string;
  children: React.ReactNode;
};

export const CollapsibleComponent = ({
  title,
  children,
  id,
  openByDefault = true,
}: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(openByDefault);
  /*Some attributes have white space which aria-control attribute
  does not allow e.g. Watch Color Dial(s) */
  const removeWhiteSpaceId = id.replace(/\s/g, '');
  return (
    <>
      {/*Having the OnClick handler on the div rather than the button is for the user
       to be able to mouse click anywhere near the title and the panel expands/collapses
       Wrapping the Chevron images with a button element allows us to utilize the accessibility
       attributes for screen reades and voice over to expands/collapse the panel*/}
      <div className={style.container} onClick={() => setIsOpen(!isOpen)}>
        <h2 className={style.title}>{title}</h2>
        <button
          className={style.button}
          aria-controls={removeWhiteSpaceId}
          aria-expanded={isOpen}
          aria-label={title + ' filter'}
          id={removeWhiteSpaceId + 'filter'}
        >
          {isOpen ? (
            <Collection24.ChevronUp24 className={style.chevron} />
          ) : (
            <Collection24.ChevronDown24 className={style.chevron} />
          )}
        </button>
      </div>
      {isOpen && (
        <div
          id={removeWhiteSpaceId}
          role="region"
          aria-labelledby={removeWhiteSpaceId + 'filter'}
        >
          {children}
        </div>
      )}
    </>
  );
};
