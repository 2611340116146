import _ from 'lodash';
import { connectRefinementList } from 'react-instantsearch-dom';
import { RefinementListProvided } from 'react-instantsearch-core';
import React, { useState, useEffect, RefObject } from 'react';
import style from './index.module.css';
import Search from '../../../assets/search.svg';
import { escapeSpecialCharacters } from '../../../utils/strUtils';
import { Paragraph16Regular } from '@sothebys/sterling/typography';
import { isBrowser } from '../../../utils/utils';
import { CollapsibleComponent } from '../../algolia/collapsible_component';
import OptionsList, { RefinementItem } from './options_list';

export const uniqByLabel = (currentItems: RefinementItem[]) => {
  currentItems.map((item) => (item['isCurrent'] = true));
  let seen: { [key: string]: any } = {};

  return currentItems.filter((item) => {
    const val = item.label;

    if (seen[val]) {
      return false;
    } else {
      seen[val] = true;
      return true;
    }
  });
};

const RefinementList = ({
  items,
  refine,
  refinement,
  attribute,
  sidebarRef,
}: RefinementListProvided & {
  refinement: {
    label?: string;
    itemMap?: {
      [key: string]: string;
    };
    collapsed?: boolean;
  };
  attribute: string;
  sidebarRef?: RefObject<HTMLDivElement>;
}) => {
  const [query, setQuery] = useState('');
  const [listExpanded, setListExpanded] = useState(false);
  const [listExpandedMobile, setListExpandedMobile] = useState(false);

  if (items.length <= 0) {
    return null;
  }

  const handleShowMoreClick = (isMobile?: boolean) => {
    if (isMobile && !listExpandedMobile && !listExpanded) {
      setListExpandedMobile(true);
    } else {
      setListExpanded(!listExpanded);
      setListExpandedMobile(false);
    }
  };

  const uniqItemsByLabel = uniqByLabel(items);
  const combinedItemsWithCustomLables = uniqItemsByLabel.map((item) => {
    const label = _.get(refinement, ['itemMap', item.label], undefined);
    return { ...item, label: label === undefined ? item.label : label };
  });

  const filteredItems = combinedItemsWithCustomLables
    .filter((item) => {
      const queryForRegex = escapeSpecialCharacters(query);
      const regex = new RegExp(queryForRegex, 'i');
      return (
        !queryForRegex || _.isEmpty(queryForRegex) || item.label.match(regex)
      );
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const itemsToShow = listExpanded ? filteredItems.length : 5;

  const renderOptions = ({
    itemsToShow,
    isMobile,
  }: {
    itemsToShow?: number;
    isMobile?: boolean;
  }) => {
    return (
      <>
        <div
          className={`${
            listExpanded && filteredItems.length > 5 ? style.optionsWrapper : ''
          }${isMobile ? style.mobileView : ''}
        `}
        >
          <OptionsList
            listExpanded={listExpanded}
            listExpandedMobile={listExpandedMobile}
            filteredItems={filteredItems}
            itemsToShow={itemsToShow}
            refine={refine}
            optionsClassName={style.options}
            gradientClassName={style.optionsGradient}
          />
        </div>
        {isMobile && <LockSidebarScroll />}
      </>
    );
  };

  const LockSidebarScroll = () => {
    useEffect(() => {
      const sidebar = sidebarRef && sidebarRef.current;
      if (isBrowser() && sidebar) {
        // Prevent scrolling on mount
        const topPos = sidebar.scrollTop;
        sidebar.style.overflowY = 'hidden';
        sidebar.scrollTo({ top: 0 });

        // Re-enable scrolling when component unmounts
        return () => {
          sidebar.style.overflowY = 'auto';
          sidebar.scrollTo({ top: topPos });
        };
      }
    }, []); // Empty array ensures effect is only run on mount and unmount
    return null;
  };

  const renderSearchbox = (uniqueId: string) => {
    const ariaId = 'aria-search-message-' + uniqueId;
    return (
      <>
        <p className={style.ariaSearchMessage} id={ariaId}>
          Filters are automatically updated when you search
        </p>
        <div className={style.searchBox}>
          <input
            placeholder={`Search ${refinement?.label || attribute}`}
            aria-label={`Search ${refinement?.label || attribute}`}
            className={style.inputBox}
            value={query}
            onChange={(event) => setQuery(event.currentTarget.value)}
            aria-describedby={ariaId}
          />
          <div className={style.searchLogo}>
            <img src={Search} alt="" />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={style.filterWrapper}>
      <CollapsibleComponent
        title={refinement.label || attribute}
        id={attribute}
        openByDefault={!refinement?.collapsed}
      >
        {uniqItemsByLabel.length > 5 && renderSearchbox(attribute)}
        {listExpandedMobile && (
          <div className={style.expandedOptionsWrapper} role="dialog">
            <div
              tabIndex={0}
              role="button"
              onClick={() => setListExpandedMobile(false)}
              className={style.goBackLink}
              onKeyDown={(e) => {
                if (e.key == 'Enter' || e.key == ' ') {
                  handleShowMoreClick();
                }
              }}
            >
              <Paragraph16Regular>Go back</Paragraph16Regular>
            </div>
            <h2 className={style.accordionHeader}>
              {refinement.label || attribute}
            </h2>
            {uniqItemsByLabel.length > 5 && renderSearchbox(attribute + '2')}
            <div className={style.optionsContainer}>
              {renderOptions({ isMobile: true })}
            </div>
          </div>
        )}
        {!listExpandedMobile && renderOptions({ itemsToShow })}
        {filteredItems.length > 5 && (
          <>
            <span
              aria-label={
                listExpanded || listExpandedMobile
                  ? 'See less filters'
                  : 'See more filters'
              }
              role="button"
              tabIndex={0}
              className={style.showMore}
              onClick={() => handleShowMoreClick()}
              onKeyDown={(e) => {
                if (e.key == 'Enter' || e.key == ' ') {
                  handleShowMoreClick();
                }
              }}
            >
              {listExpanded || listExpandedMobile ? 'See less' : 'See more'}
            </span>
            <span
              aria-label={
                listExpanded || listExpandedMobile
                  ? 'See less filters'
                  : 'See more filters'
              }
              role="button"
              tabIndex={0}
              className={style.showMoreMobile}
              onClick={() => handleShowMoreClick(true)}
              onKeyDown={(e) => {
                if (e.key == 'Enter' || e.key == ' ') {
                  handleShowMoreClick(true);
                }
              }}
            >
              {listExpanded || listExpandedMobile ? 'See less' : 'See more'}
            </span>
          </>
        )}
      </CollapsibleComponent>
    </div>
  );
};

const CustomRefinementList = connectRefinementList(RefinementList);

export default CustomRefinementList;
