import React from 'react';
import _ from 'lodash';
import style from './index.module.css';
import { createConnector } from 'react-instantsearch-dom';
import { CollapsibleComponent } from '../collapsible_component';

import { PriceFilterEnabled } from '../../../FeatureFlags';
import RangeInput from '../../../atoms/price_range_input';

let rangeMin: number | undefined;
let rangeMax: number | undefined;

export type Facet = { name: string; stats: { max: number; min: number } };

export const getMaxMinPriceFromFacets = (facets: Array<Facet>) => {
  const lowEstimateInfo = _.find(
    facets,
    (facet) => facet.name === 'lowEstimate',
  );
  const highEstimateInfo = _.find(
    facets,
    (facet) => facet.name === 'highEstimate',
  );
  return {
    min: _.min([lowEstimateInfo?.stats.min, highEstimateInfo?.stats.min]),
    max: _.max([lowEstimateInfo?.stats.max, highEstimateInfo?.stats.max]),
  };
};

const connectPriceFilter = createConnector({
  displayName: 'PriceFilterWidget',
  getProvidedProps(props, searchState, searchResults) {
    const { max, min } = getMaxMinPriceFromFacets(
      searchResults.results && searchResults.results.disjunctiveFacets,
    );

    rangeMin = min;
    rangeMax = max;

    const currentRefinement: Refinement = {
      priceFilterLow: parseInt(searchState.priceFilterLow) || rangeMin,
      priceFilterHigh: parseInt(searchState.priceFilterHigh) || rangeMax,
      min: rangeMin,
      max: rangeMax,
    };

    return { currentRefinement };
  },
  refine(_props, searchState, nextRefinement) {
    return {
      ...searchState,
      priceFilterLow: nextRefinement.priceFilterLow,
      priceFilterHigh: nextRefinement.priceFilterHigh,
    };
  },
  getSearchParameters(searchParameters, props, searchState) {
    let search = searchParameters
      .addDisjunctiveFacet('lowEstimate')
      .addDisjunctiveFacet('highEstimate');
    if (searchState.priceFilterHigh && searchState.priceFilterLow) {
      if (PriceFilterEnabled()) {
        return search
          .addNumericRefinement('lowEstimate', '>=', searchState.priceFilterLow)
          .addNumericRefinement(
            'highEstimate',
            '<=',
            searchState.priceFilterHigh,
          );
      } else {
        return search
          .addNumericRefinement(
            'lowEstimate',
            '<=',
            searchState.priceFilterHigh,
          )
          .addNumericRefinement(
            'highEstimate',
            '>=',
            searchState.priceFilterLow,
          );
      }
    }
    return search;
  },
  cleanUp(_props, searchState) {
    const { priceFilterLow, priceFilterHigh, ...nextSearchState } = searchState;
    return nextSearchState;
  },
});

export type Refinement = {
  min?: number;
  max?: number;
  priceFilterLow?: number;
  priceFilterHigh?: number;
};

type CustomPriceFilterProps = {
  currentRefinement: Refinement;
  refine(value?: { priceFilterLow: number; priceFilterHigh: number }): void;
  refinement: {
    label?: string;
    itemMap?: {
      [key: string]: string;
    };
    collapsed?: boolean;
  };
  attribute: string;
};

const CustomPriceFilter = ({
  currentRefinement,
  refine,
  attribute,
  refinement,
}: CustomPriceFilterProps) => {
  return (
    <div className={style.filterWrapper}>
      <CollapsibleComponent
        title={refinement && refinement.label ? refinement.label : attribute}
        id={attribute}
        openByDefault={!refinement?.collapsed}
      >
        <RangeInput
          onChange={(newMinUSD: number, newHighUSD: number) => {
            refine({
              priceFilterLow: newMinUSD,
              priceFilterHigh: newHighUSD,
            });
          }}
          priceFilterLowUSD={currentRefinement.priceFilterLow}
          priceFilterHighUSD={currentRefinement.priceFilterHigh}
          defaultLowUSD={currentRefinement.min}
          defaultHighUSD={currentRefinement.max}
        />
      </CollapsibleComponent>
    </div>
  );
};

const ConnectedPriceFilter = connectPriceFilter(CustomPriceFilter);

export default ConnectedPriceFilter;
