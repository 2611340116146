import React, { useEffect } from 'react';
import { Media } from '../../client';
import { getCollectionImageBySize } from '../../utils/utils';
import {
  Headline24Regular,
  Headline32Regular,
} from '@sothebys/sterling/typography';
import InnerHtml from '../inner_html';

import style from './index.module.css';

type CollectionHeaderProps = {
  title: string;
  description: string;
  setImageExists: (value: boolean) => void;
  media?: Media;
};

const CollectionHeader = ({
  title,
  description,
  setImageExists,
  media,
}: CollectionHeaderProps) => {
  if (media) {
    const imageExtraLarge = getCollectionImageBySize(media, 'ExtraLarge');
    const imageLarge = getCollectionImageBySize(media, 'Large');
    const imageUrlExtraLarge = imageExtraLarge?.url;
    const imageUrlLarge = imageLarge?.url;
    const descriptionOpenInSameWindow = description?.replace(
      /href/g,
      'target=_self href',
    );

    if (imageUrlExtraLarge || imageUrlLarge) {
      return (
        <div className={style.container}>
          <div className={style.imageContainer}>
            <div
              className={`${style.visibleDesktopHiddenMobile} ${style.image}`}
              style={{ backgroundImage: `url(${imageUrlExtraLarge})` }}
            ></div>
            <div
              className={`${style.visibleMobileHiddenDesktop} ${style.image}`}
              style={{ backgroundImage: `url(${imageUrlLarge})` }}
            ></div>
            <div
              className={style.titlecard}
              role="banner"
              aria-label="Hero Image"
            >
              <Headline24Regular className={style.visibleMobileHiddenDesktop}>
                {title}
              </Headline24Regular>
              <Headline32Regular className={style.visibleDesktopHiddenMobile}>
                {title}
              </Headline32Regular>
              <div className={style.rectangle}></div>
              <InnerHtml data={descriptionOpenInSameWindow} />
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <CollectionHeaderEmptyContainer
      setImageExists={setImageExists}
      title={title}
    />
  );
};

type CollectionHeaderEmptyContainerProps = {
  setImageExists: (value: boolean) => void;
  title: string;
};

const CollectionHeaderEmptyContainer = ({
  setImageExists,
  title,
}: CollectionHeaderEmptyContainerProps) => {
  useEffect(() => {
    setImageExists(false);
  }, []);

  return (
    <div className={style.emptyContainer}>
      <Headline24Regular className={style.visibleMobileHiddenDesktop}>
        {title}
      </Headline24Regular>
      <Headline32Regular className={style.visibleDesktopHiddenMobile}>
        {title}
      </Headline32Regular>
      <div className={style.rectangle}></div>
    </div>
  );
};
export default CollectionHeader;
