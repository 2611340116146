import React, { useRef, useEffect } from 'react';
import { connectScrollTo } from 'react-instantsearch-dom';
import { isBrowser } from '../../../utils/utils';

type ScrollToProps = {
  value: any;
  children: React.ReactElement;
  hasNotChanged: boolean;
  executeAfterScroll: () => void;
};

const scrollToWithCallback = (offset: number, callback: () => void) => {
  const fixedOffset = offset.toFixed(),
    onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener('scroll', onScroll);
        callback();
      }
    };

  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo({
    top: offset,
    behavior: 'smooth',
  });
};

const ScrollToComponent = ({
  value,
  hasNotChanged,
  executeAfterScroll,
  children,
}: ScrollToProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (
      isBrowser() &&
      value &&
      hasNotChanged &&
      wrapperRef.current?.offsetTop
    ) {
      scrollToWithCallback(wrapperRef.current?.offsetTop, () => {
        executeAfterScroll();
      });
    }
  }, [value]);
  return <div ref={wrapperRef}>{children}</div>;
};

const ScrollTo = connectScrollTo(ScrollToComponent);

export default ScrollTo;
