import { NextPageContext } from 'next';
import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { findResultsState } from 'react-instantsearch-dom/server';
import { SearchState } from 'react-instantsearch-core';
import _ from 'lodash';
import { useRouter } from 'next/router';
import qs from 'qs';

import {
  Collection,
  getCollectionAndAlgoliaSearchKey,
  Media,
} from '../../client/client';
import appConfig from '../../client/config';
import Head from 'next/head';
import {
  getTokens,
  tokensToFormattedString,
} from '../../client/utils/strUtils';

import { useAuthentication } from '../../client/hooks/AuthenticationHook';
import { isBrowser } from '../../client/utils/utils';
import { FeatureFlagMenu } from '../../client/FeatureFlags';
import { getDefaultSortByItem } from '../../client/components/algolia/sort_by';
import BrowsePage, { BrowsePageProps } from '../../client/routes/BrowsePage';
import { parseAsPath, getHost } from '../../pageUtils';

const getAlgoliaSearchClient = (key: string) => {
  return algoliasearch(appConfig.algolia.appID(), key);
};

type NextBrowsePageProps = {
  resultsState: object;
  algoliaQuery: string;
  indexName: string;
  path: string;
  host: string;
  location: string;
  algoliaSearchKey: string;
  language: string;
  collectionSlug: string;
  collectionTitle: string;
  heroDescription: string;
  media?: Media;
  queryString: string;
  collection: Collection;
  allFacetsFromAlgolia: { [key: string]: string[] };
};

interface MetadataObj {
  [key: string]: any;
}

// takes query string and converts into search state
const queryStringToSearchState = (queryString: string) => {
  const queryObject: MetadataObj = qs.parse(queryString);

  const {
    query = '',
    page = 1,
    priceFilterLow,
    priceFilterHigh,
    range,
    sortBy = getDefaultSortByItem(),
    ...refinements
  } = queryObject;

  return {
    query: decodeURIComponent(query as string),
    page,
    priceFilterLow,
    priceFilterHigh,
    range,
    sortBy,
    refinementList: _.mapValues(refinements || {}, (refinement, key) => {
      if (Array.isArray(refinement) && refinement.length) {
        return _.map((refinement as string[]) || [], decodeURIComponent);
      } else {
        return [decodeURIComponent(refinement as string)];
      }
    }),
  };
};

const NextBrowsePage = (props: NextBrowsePageProps) => {
  // The hook is currently not used, will be used in the future.
  // Placed here to start the auth flow.
  const _tokenState = useAuthentication('NextBrowsePage');
  const router = useRouter();

  const [searchState, setSearchState] = useState<SearchState>(
    queryStringToSearchState(props.queryString),
  );

  // takes the search state and creates a query string
  const createQueryString = (
    state: SearchState,
    includeCurrentQuery: boolean,
  ) => {
    const {
      query = '',
      page = 1,
      refinementList,
      priceFilterLow,
      priceFilterHigh,
      range,
      sortBy,
    } = state;

    const queryParameters: any = includeCurrentQuery ? router.query : {};

    if (state.query) {
      queryParameters.query = encodeURIComponent(query);
    }
    if (state.page !== 1) {
      queryParameters.page = page;
    }

    // Sort By has a default value, so we only want to add it as a URL query parameter if not default is selected
    if (sortBy && sortBy !== getDefaultSortByItem()) {
      queryParameters.sortBy = sortBy;
    }

    queryParameters.priceFilterLow = priceFilterLow;
    queryParameters.priceFilterHigh = priceFilterHigh;

    _.map(refinementList, (refinement, key) => {
      if (refinement.length) {
        if (Array.isArray(refinement)) {
          queryParameters[key] = refinement.map(encodeURIComponent);
        } else {
          queryParameters[key] = encodeURIComponent(refinement);
        }
      }
    });
    queryParameters.range = range;
    const queryString = qs.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    });
    return queryString;
  };

  const searchStateToUrl = (location: Location, searchState: SearchState) =>
    searchState
      ? `${location.pathname}${createQueryString(searchState, false)}`
      : '';

  /*
    onSearchStateChange is passed into the InstantSearch component
    in the /routes/BrowsePage.tsx

    whenever the search state is changed by interacting with the
    filters, this function will be called
  */
  const onSearchStateChange = (updatedSearchState: SearchState) => {
    if (isBrowser()) {
      const cleanedState = { ...updatedSearchState, configure: undefined };

      setSearchState(updatedSearchState);

      const href = {
        pathname: router.pathname,
        query: router.query,
      };

      const as = searchStateToUrl(window.location, cleanedState);

      router.replace(href, as, { shallow: true });
    }
  };

  const HeadTags = () => {
    const { collectionTitle, path } = props;
    const splitPath = getTokens(path);
    const department = splitPath.length > 1 ? _.first(splitPath) : undefined;
    return (
      <Head>
        <title>
          {tokensToFormattedString({
            tokens: [collectionTitle, department, "Sotheby's"],
          })}
        </title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
    );
  };

  return (
    <>
      {appConfig.tier() !== 'prod' && <FeatureFlagMenu />}
      <HeadTags />

      <BrowsePage
        onSearchStateChange={onSearchStateChange}
        searchState={searchState}
        searchClient={getAlgoliaSearchClient(props.algoliaSearchKey)}
        indexName={props.indexName}
        resultsState={props.resultsState}
        algoliaQuery={props.algoliaQuery}
        path={props.path}
        language={props.language}
        collectionTitle={props.collectionTitle}
        heroDescription={props.heroDescription}
        collectionSlug={props.collectionSlug}
        media={props.media}
        collection={props.collection}
        host={props.host}
        allFacetsFromAlgolia={props.allFacetsFromAlgolia}
      />
    </>
  );
};

NextBrowsePage.getInitialProps = async (context: NextPageContext) => {
  if (!context.asPath) {
    throw new Error(
      'asPath should not be missing if we are rendering BrowsePage',
    );
  }
  const urlInfo = parseAsPath(context.asPath);

  const collectionAndAlgoliaSearchKeyResponse =
    await getCollectionAndAlgoliaSearchKey(
      urlInfo.collectionSlug || '',
      urlInfo.language,
    );

  const collection = collectionAndAlgoliaSearchKeyResponse.collection;
  const algoliaQuery =
    collectionAndAlgoliaSearchKeyResponse.collection.algoliaQuery;

  const collectionTitle =
    collectionAndAlgoliaSearchKeyResponse.collection.title;

  const heroDescription =
    collectionAndAlgoliaSearchKeyResponse.collection.heroDescription;

  const algoliaSearchKey =
    collectionAndAlgoliaSearchKeyResponse.algoliaSearchKey.key;

  const media = collectionAndAlgoliaSearchKeyResponse.collection.media;

  const searchClient = getAlgoliaSearchClient(algoliaSearchKey);

  let allFacetsFromAlgolia: { [key: string]: string[] } = {};
  try {
    // If collections filter string includes department then the result response doens't
    // facet['department'] so we need to do a request for facet departments to use in analytics events
    const facetResult = await searchClient.search([
      {
        indexName: appConfig.algolia.productIndexName(),
        params: { facets: ['*'], filters: JSON.parse(algoliaQuery).filters },
      },
    ]);

    const allFacets = facetResult?.results[0]?.facets;
    if (allFacets) {
      const formattedFacets: { [key: string]: string[] } = {};
      Object.keys(allFacets).forEach(
        (objectKey) =>
          (formattedFacets[objectKey] = Object.keys(allFacets[objectKey])),
      );
      allFacetsFromAlgolia = formattedFacets;
    }
  } catch (error) {
    // We should probably have some sort of logging here
  }

  // widgetsCollector undefined is to remove that type since it's not needed
  const resultsState = await findResultsState<
    BrowsePageProps & { widgetsCollector: undefined }
  >(BrowsePage, {
    widgetsCollector: undefined,
    searchClient: getAlgoliaSearchClient(algoliaSearchKey),
    indexName: appConfig.algolia.productIndexName(),
    searchState: {
      ...queryStringToSearchState(urlInfo.queryString || ''),
      facets: ['department'],
      configure: {
        clickAnalytics: appConfig.algolia.clickAnalytics(),
      },
    },
    collectionSlug: urlInfo.collectionSlug || '',
    algoliaQuery,
    language: urlInfo.language,
    collectionTitle,
    path: urlInfo.collectionSlug || '',
    host: getHost(context.req?.headers.host) || '',
    heroDescription: heroDescription || '',
    allFacetsFromAlgolia,
    collection,
    onSearchStateChange: (s: any): void => {
      throw new Error('Function not implemented.');
    },
    resultsState: undefined,
  });

  return {
    resultsState,
    collection,
    algoliaQuery,
    collectionSlug: urlInfo.collectionSlug || null,
    indexName: appConfig.algolia.productIndexName(),
    algoliaSearchKey: algoliaSearchKey,
    language: urlInfo.language,
    collectionTitle,
    heroDescription,
    media,
    queryString: urlInfo.queryString || null,
    host: getHost(context.req?.headers.host) || null,
    path: urlInfo.collectionSlug,
    allFacetsFromAlgolia,
  };
};

export default NextBrowsePage;
