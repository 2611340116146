import React from 'react';
import { StateResultsProvided, SearchResults } from 'react-instantsearch-core';
import { connectStateResults } from 'react-instantsearch-dom';

import {
  trackProductListViewed,
  updateProductListFilteredTrackingData,
  ProductListFilteredDataProps,
  productListFilteredDataEmptySate,
  trackProductListFiltered,
} from '../../utils/segmentTracking';
import { useAuthentication } from '../../hooks/AuthenticationHook';
import { TokenState } from '../../stores/AuthZeroStore';
import trackingStore from '../../stores/TrackingStore';

import * as AlgoliaTypes from './AlgoliaTypes';
import * as BrowsePageUtils from './utils';

const Analytics = connectStateResults<
  StateResultsProvided<AlgoliaTypes.Hit> & {
    collectionTitle: string;
    indexName: string;
    language: string;
    searchResults: SearchResults & { queryID: string };
    locale: string;
    pathName: string;
    allFacetsFromAlgolia: { [key: string]: string[] };
  }
>(
  ({
    searchState,
    searchResults,
    collectionTitle,
    indexName,
    language,
    locale,
    pathName,
    allFacetsFromAlgolia,
  }) => {
    /**
     * Takes care of updating tracking store, used in some analytic events in segmentTracking.ts
     */
    React.useEffect(() => {
      trackingStore.setState({
        ...trackingStore.getState(),
        algoliaQueryID: searchResults.queryID,
        algoliaIndexName: indexName,
      });
    }, [searchResults]);

    /**
     * Helper functions used in the analytics code
     */

    const constructProductsFromHits = (
      hits: AlgoliaTypes.Hit[],
      currentPage: number,
      locale: string,
      pathName: string,
      searchResults: SearchResults,
    ) => {
      return hits.map((hit, index) => {
        return {
          brand: hit.creators?.join(' | ') || null,
          category: hit.categories?.lvl0?.[0] || null,
          image_url: hit.imageUrl || null,
          name: hit.title || null,
          position: index + 1,
          price: hit.listPrice || null,
          product_id: hit.objectID,
          quantity: hit.availableQuantity || null,
          sku: hit.sku || null,
          url:
            BrowsePageUtils.getHitLinkToPDP(hit, locale, pathName).as || null,
          currency: hit.currency || null,
          clean_name: hit.title?.replace(new RegExp(/[,;|\\]/g), '') || null,
          product_list_position:
            searchResults.nbPages +
            ':' +
            searchResults.nbHits +
            ':' +
            currentPage +
            ':' +
            hits.length +
            ':' +
            (index + 1),
          number_of_variants:
            hit.retailItemGroup?.variants &&
            hit.retailItemGroup?.variants?.length > 1
              ? hit.retailItemGroup.variants.length
              : null,
        };
      });
    };

    /**
     * TrackProductListFiltered analytic call
     */
    const [trackingData, setTrackingData] =
      React.useState<ProductListFilteredDataProps>(
        productListFilteredDataEmptySate,
      );
    React.useEffect(() => {
      const nbHits = searchResults && searchResults.nbHits;
      const lastAppliedFilterValue = trackingData.lastAppliedValue;

      updateProductListFilteredTrackingData({
        searchState,
        trackingData,
        setTrackingData,
      });

      let appliedFilterAttributes = trackingData.appliedFilters.map(
        (filter) => filter.attribute,
      );

      if (
        searchResults &&
        trackingData.appliedFilters.length > 0 &&
        lastAppliedFilterValue !== trackingData.lastAppliedValue &&
        appliedFilterAttributes.some(
          (filter) =>
            filter === 'price' || // we use a custom price filter
            Object.keys(allFacetsFromAlgolia).includes(filter),
        )
      ) {
        const hits = searchResults && searchResults.hits;
        const currentPage = Number(searchResults.page) + 1;
        trackProductListFiltered({
          collection: collectionTitle,
          appliedFilters: trackingData.appliedFilters,
          search_keyword: trackingData.searchQuery,
          filter_applied: trackingData.lastAppliedFilter,
          filter_widget_type: trackingData.filterWidgetType,
          num_search_results: nbHits,
          waysToBuy: trackingData.appliedFilters
            .filter((filter) => filter.attribute === 'waysToBuy')
            .map((wayToBuy) => wayToBuy.value),
          user: authState.token?.user,
          products: constructProductsFromHits(
            hits,
            currentPage,
            locale,
            pathName,
            searchResults,
          ),
          currentPage: currentPage,
          totalPages: searchResults.nbPages,
          totalNumberOfItems: searchResults.nbHits,
          itemsPerPage: searchResults.hitsPerPage,
          objectIDs: hits.map((hit) => hit.objectID),
        });
      }
    }, [searchResults]);

    /**
     * TrackProductListViewed analytic call
     */
    const authState = useAuthentication('BrowsePageAlgoliaConnectAnalytics');
    const [lastPage, setLastPage] = React.useState<number | undefined>(
      undefined,
    );

    // Used to trigger the event, since we call it under different conditions below
    const executeTrackListViewedEvent = () => {
      const hits = searchResults && searchResults.hits;
      let departments: string[] = [];
      let categories: string[] = [];
      let waysToBuy: string[] = [];
      hits.map((hit) => {
        const wayToBuyHit = hit.waysToBuy;
        if (wayToBuyHit && !waysToBuy.includes(wayToBuyHit)) {
          waysToBuy.push(wayToBuyHit);
        }
      });

      Object.entries(allFacetsFromAlgolia).forEach(([facetName, facetData]) => {
        if (typeof facetData === 'object' && facetName !== null) {
          if (facetName === 'department') {
            departments = facetData;
          } else if (facetName.includes('categories.lvl')) {
            const categoriesLvl = facetData;
            categories = categories.concat(categoriesLvl);
          }
        }
      });

      const currentPage = Number(searchResults.page) + 1;

      const productListViewedAttributes = {
        collectionTitle,
        language,
        departments,
        categories,
        waysToBuy,
        products: constructProductsFromHits(
          hits,
          currentPage,
          locale,
          pathName,
          searchResults,
        ),
        currentPage: currentPage,
        totalPages: searchResults.nbPages,
        totalNumberOfItems: searchResults.nbHits,
        itemsPerPage: searchResults.hitsPerPage,
        objectIDs: hits.map((hit) => hit.objectID),
      };
      if (authState.tokenState === TokenState.UNAUTHENTICATED) {
        // guest flow
        trackProductListViewed({
          ...productListViewedAttributes,
          loggedIn: false,
        });
      } else if (authState.tokenState === TokenState.AUTHENTICATED) {
        // logged in customer
        trackProductListViewed({
          ...productListViewedAttributes,
          user: authState.token?.user,
          loggedIn: true,
        });
      }
    };

    // Used to trigger analytics call on pagination transitions
    React.useEffect(() => {
      if (lastPage !== undefined && lastPage !== searchResults?.page) {
        executeTrackListViewedEvent();
      }
      setLastPage(searchResults.page);
    }, [searchResults?.page]);

    // Trigger inital event after we know if the user is authenticated or not
    React.useEffect(() => {
      if (authState.tokenState !== TokenState.NOT_FETCHED) {
        executeTrackListViewedEvent();
      }
    }, [authState]);

    /**
     * Nothing is rendered by this component since we only need to be able to hook into algolia search results
     * with the connectStateResults connector from algolia
     */
    return null;
  },
);

export default Analytics;
