import React from 'react';
import style from './index.module.css';

type Props = {
  onChange?: () => void;
  label?: string;
  checked?: boolean;
  name: string;
};

const Radio = ({ label, checked, onChange, name }: Props) => {
  return (
    <label className={style.label}>
      {label}
      <input
        aria-label={label}
        className={style.radioInput}
        type="radio"
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <span className={style.checkmark}></span>
    </label>
  );
};
export default Radio;
