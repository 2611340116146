import React from 'react';
import _ from 'lodash';
import style from './index.module.css';
import * as InstantSearch from 'react-instantsearch-dom';
import { RangeSlider as RangeSliderComponent } from '../../../atoms/range_slider';
import { PublicState } from 'rheostat';
import { CollapsibleComponent } from '../../algolia/collapsible_component';

export type Facet = { name: string; stats: { max: number; min: number } };

type CustomRangeFilterProps = {
  min: number;
  max: number;

  currentRefinement: {
    min: number;
    max: number;
  };
  refine(value?: { min: number; max: number }): void;
  refinement: {
    label?: string;
    itemMap?: {
      [key: string]: string;
    };
    collapsed?: boolean;
  };
  attribute: string;
  canRefine: boolean;
};

const CustomRangeFilter = ({
  min,
  max,
  currentRefinement,
  canRefine,
  refinement,
  attribute,
  refine,
}: CustomRangeFilterProps) => {
  const [stateMin, setStateMin] = React.useState(min);
  const [stateMax, setStateMax] = React.useState(max);

  React.useEffect(() => {
    if (canRefine) {
      setStateMin(currentRefinement.min);
      setStateMax(currentRefinement.max);
    }
  }, [currentRefinement.min, currentRefinement.max]);

  if (min === max) {
    return null;
  }

  const onChange = ({ values: [min, max] }: PublicState) => {
    if (currentRefinement.min !== min || currentRefinement.max !== max) {
      refine({ min: min, max: max });
    }
  };

  const onValuesUpdated = ({ values: [min, max] }: PublicState) => {
    setStateMin(min);
    setStateMax(max);
  };

  return (
    <div className={style.filterWrapper}>
      <CollapsibleComponent
        title={refinement && refinement.label ? refinement.label : attribute}
        id={attribute}
        openByDefault={!refinement?.collapsed}
      >
        <RangeSliderComponent
          onChange={onChange}
          onValuesUpdated={onValuesUpdated}
          min={min}
          max={max}
          sliderPosLow={stateMin}
          sliderPosHigh={stateMax}
          currentRefinement={currentRefinement}
        />
      </CollapsibleComponent>
    </div>
  );
};

const ConnectedPriceFilter = InstantSearch.connectRange(CustomRangeFilter);

export default ConnectedPriceFilter;
