import React from 'react';
import style from './index.module.css';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

type ClearRefinementsProps = {
  items: {
    label: string;
    attribute: string;
  }[];
  refine: (items: any) => void;
};

const ClearRefinementsComponent = ({
  items,
  refine,
}: ClearRefinementsProps) => (
  <div
    onClick={() => refine(items)}
    className={`${style.clearAll} ${!items.length ? style.hidden : ''}`}
  >
    Clear all filters
  </div>
);

export const ClearRefinements = connectCurrentRefinements(
  ClearRefinementsComponent,
);
