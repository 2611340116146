import React, { CSSProperties } from 'react';
import style from './index.module.css';

type Props = {
  style: CSSProperties;
  isActive: boolean;
  isDisabled: boolean;
  orientation?: 'horizontal' | 'vertical';
};

const ProgressBar = ({
  style: inlineStyle,
  isActive,
  isDisabled,
  orientation,
}: Props) => (
  <div
    className={`${style.progressBar} ${isActive ? style.active : ''} ${
      isDisabled ? style.disable : ''
    } ${!orientation || orientation === 'horizontal' ? style.horizontal : ''}
        ${orientation === 'vertical' ? style.vertical : ''}`}
    style={inlineStyle}
  ></div>
);

export default ProgressBar;
