import React from 'react';
import { Label14Medium } from '@sothebys/sterling/typography';
import CurrencyAmount from '../currency_amount';
import { CardType } from '../../shared/types';
import { Currency } from '../../client';

import style from './index.module.css';

type PriceProps = {
  type: CardType;
  currency?: Currency;
  price?: number;
  lowestPrice?: number;
  lowEstimate?: number;
  highEstimate?: number;
  toFloat?: boolean;
};

const Price = ({
  type,
  currency,
  price,
  lowestPrice,
  lowEstimate,
  highEstimate,
  toFloat = false,
}: PriceProps) => {
  switch (type) {
    case CardType.estimatedPriceCard:
      if (lowEstimate != null && highEstimate != null && currency != null) {
        return (
          <div className={style.priceInfo}>
            <CurrencyAmount
              listPrice={lowEstimate}
              currency={currency}
              renderAmount={(amount) => <Label14Medium>{amount}</Label14Medium>}
              renderTooltip={(amount) => (
                <Label14Medium>{amount}</Label14Medium>
              )}
              toFloat={toFloat}
            />
            <CurrencyAmount
              listPrice={highEstimate}
              currency={currency}
              renderAmount={(amount) => <Label14Medium>{amount}</Label14Medium>}
              renderTooltip={(amount) => (
                <Label14Medium>{amount}</Label14Medium>
              )}
              toFloat={toFloat}
            />
          </div>
        );
      }

    case CardType.fixedPriceCard:
    case CardType.enquireCard:
      if (price != null && currency != null) {
        return (
          <CurrencyAmount
            listPrice={typeof lowestPrice === 'number' ? lowestPrice : price}
            currency={currency}
            renderAmount={(amount) => (
              <Label14Medium className={style.listPrice}>
                {typeof lowestPrice === 'number' ? 'From ' : ''}
                {amount}
              </Label14Medium>
            )}
            renderTooltip={(amount) => <Label14Medium>{amount}</Label14Medium>}
            toFloat={toFloat}
          />
        );
      }
  }

  return null;
};

export default Price;
