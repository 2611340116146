import qs from 'querystring';
import * as AlgoliaTypes from './AlgoliaTypes';

export const getHitLinkToPDP = (
  hit: AlgoliaTypes.Hit,
  locale: string,
  currentPathName: string,
) => {
  if (hit.waysToBuy === 'bid') {
    return {
      href: `${hit.slug}`,
      as: `${hit.slug}`,
    };
  } else {
    let retailItemKey = `_${hit.slug || hit.objectID}`;
    const href = {
      pathname: '/ProductDetailPage',
      query: {
        locale,
      },
    };

    let trimmedCurrentPathName = currentPathName;

    // private sales do not support new PDP url format
    if (hit.waysToBuy === 'private') {
      trimmedCurrentPathName = currentPathName?.replace(/^\/+|\/+$/g, '');
      const query = locale ? `?locale=${locale}` : '';

      return {
        href,
        as: `${trimmedCurrentPathName}/_${hit.slug || hit.objectID}${query}`,
      };
    }

    let urlWithoutDuplicates = Array.from(new Set(hit.url?.split('/'))).join(
      '/',
    );

    return {
      href: href,
      as: `en/buy/${urlWithoutDuplicates || retailItemKey}${
        locale ? '?' + qs.stringify({ locale: locale }) : ''
      }`,
    };
  }
};

export const isSneakersHit = (objectTypes?: Array<string>) =>
  objectTypes && objectTypes.indexOf('Sneakers') >= 0;

export const formatAlgoliaCollectionSlug = (
  collectionSlug?: string,
): string | undefined => {
  const splitCollectionSlug = collectionSlug?.split('/');
  const formattedSlugParts = splitCollectionSlug?.map(
    (part) => part?.replaceAll('&', 'and'), // algolia does not support the & symbol in rule context names
  );
  const combinedCollectionSlug = formattedSlugParts?.join('_');
  return combinedCollectionSlug;
};
