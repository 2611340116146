const localStorageKey = 'MARKETPLACE_GUEST_BAG_ID';
const localStorageAlcoholBagKey = 'MARKETPLACE_ALCOHOL_GUEST_BAG_ID';

export const setNewGuestBagId = (newId: string): void => {
  window.localStorage?.setItem(localStorageKey, newId);
};

export const getGuestBagId = (): string | null => {
  return window.localStorage?.getItem(localStorageKey);
};

export const clearGuestBagId = (): void => {
  window.localStorage?.removeItem(localStorageKey);
};

export const setNewAlcoholGuestBagId = (newId: string): void => {
  window.localStorage?.setItem(localStorageAlcoholBagKey, newId);
};

export const getAlcoholGuestBagId = (): string | null => {
  return window.localStorage?.getItem(localStorageAlcoholBagKey);
};

export const clearAlcoholGuestBagId = (): void => {
  window.localStorage?.removeItem(localStorageAlcoholBagKey);
};
