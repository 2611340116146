import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { Currency } from '../../client';
import {
  Headline20Regular,
  Label12Medium,
  Label14Medium,
  Label16Medium,
  Paragraph12Regular,
  Paragraph16Regular,
} from '@sothebys/sterling/typography';

import CurrencyAmount from '../currency_amount';
import Cart from '../../assets/cart.svg';
import style from './index.module.css';
import { CardType } from '../../shared/types';
import { isMobileOrTablet, quantityLabel } from '../../utils/utils';
import * as AlgoliaTypes from '../../routes/BrowsePage/AlgoliaTypes';

type PricingProps = {
  type: CardType;
  currency?: Currency;
  price?: number;
  lowestPrice?: number;
  estimatedRetailPrice?: number;
  lowEstimate?: number;
  highEstimate?: number;
  quantity?: number;
  bottleSize?: string;
  isAlcoholBag?: boolean;
};

const Pricing = ({
  type,
  currency,
  price,
  lowestPrice,
  lowEstimate,
  highEstimate,
  quantity,
  bottleSize,
  isAlcoholBag,
}: PricingProps) => {
  const qtyLabel = quantityLabel(quantity);

  switch (type) {
    case CardType.estimatedPriceCard:
      return (
        <>
          {lowEstimate != null && highEstimate != null && currency != null && (
            <div className={style.priceInfo}>
              Estimate
              <br />
              <CurrencyAmount
                listPrice={lowEstimate}
                currency={currency}
                renderAmount={(amount) => <span>{amount}</span>}
                renderTooltip={(amount) => (
                  <Label16Medium>{amount}</Label16Medium>
                )}
                toFloat={isAlcoholBag}
              />{' '}
              -{' '}
              <CurrencyAmount
                listPrice={highEstimate}
                currency={currency}
                renderAmount={(amount) => <span>{amount}</span>}
                renderTooltip={(amount) => (
                  <Label16Medium>{amount}</Label16Medium>
                )}
                toFloat={isAlcoholBag}
              />
            </div>
          )}
          {isAlcoholBag && bottleSize && (
            <Paragraph12Regular className={style.size}>
              {bottleSize}
            </Paragraph12Regular>
          )}
          {isAlcoholBag && quantity !== undefined && (
            <Paragraph12Regular
              className={`${style.stockMobile} ${
                !quantity ? style.outOfStock : ''
              }`}
            >
              {qtyLabel}
            </Paragraph12Regular>
          )}
          <p className={style.actionLabel}>Bid Now</p>
        </>
      );

    case CardType.fixedPriceCard:
      return (
        <>
          {price != null && currency != null && (
            <div className={style.priceInfo}>
              <CurrencyAmount
                listPrice={
                  typeof lowestPrice === 'number' ? lowestPrice : price
                }
                currency={currency}
                renderAmount={(amount) => (
                  <Label14Medium>
                    {typeof lowestPrice === 'number' ? 'From ' : ''}
                    {amount}
                  </Label14Medium>
                )}
                renderTooltip={(amount) => (
                  <Label14Medium>{amount}</Label14Medium>
                )}
                toFloat={isAlcoholBag}
              />
            </div>
          )}

          {isAlcoholBag && bottleSize && (
            <Paragraph12Regular className={style.size}>
              {bottleSize}
            </Paragraph12Regular>
          )}
          {isAlcoholBag && quantity !== undefined && (
            <Paragraph12Regular
              className={`${style.stockMobile} ${
                !quantity ? style.outOfStock : ''
              }`}
            >
              {qtyLabel}
            </Paragraph12Regular>
          )}

          <div
            className={`${style.actionLabel} ${style.actionLabelAndCartContainer}`}
          >
            <img src={Cart} alt="" />
            <p className={style.labelAfterIcon}>Buy Now</p>
          </div>
        </>
      );

    case CardType.noPriceCard:
      return <p className={style.actionLabel}>Enquire</p>;
    case CardType.enquireCard:
      return (
        <>
          {price != null && currency != null && (
            <div className={style.priceInfo}>
              <CurrencyAmount
                listPrice={
                  typeof lowestPrice === 'number' ? lowestPrice : price
                }
                currency={currency}
                renderAmount={(amount) => (
                  <Label14Medium>
                    {typeof lowestPrice === 'number' ? 'From ' : ''}
                    {amount}
                  </Label14Medium>
                )}
                renderTooltip={(amount) => (
                  <Label14Medium>{amount}</Label14Medium>
                )}
                toFloat={isAlcoholBag}
              />
            </div>
          )}

          {isAlcoholBag && bottleSize && (
            <Paragraph12Regular className={style.size}>
              {bottleSize}
            </Paragraph12Regular>
          )}
          {isAlcoholBag && quantity !== undefined && (
            <Paragraph12Regular
              className={`${style.stockMobile} ${
                !quantity ? style.outOfStock : ''
              }`}
            >
              {qtyLabel}
            </Paragraph12Regular>
          )}
          <p className={style.actionLabel}>Enquire</p>
        </>
      );
  }
};

type CardProps = {
  type: CardType;
  id?: string;
  image?: string;
  title?: string;
  price?: number;
  lowestPrice?: number;
  creator?: string;
  currency?: Currency;
  lowEstimate?: number;
  highEstimate?: number;
  estimatedRetailPrice?: number;
  bottleSize?: string;
  produced?: string;
  quantity?: number;
  certifiedPreOwned?: Array<boolean>;
  isAlcoholBag?: boolean;
  salesEntity?: AlgoliaTypes.Hit['salesEntity'];
};

const GridCard = ({
  creator,
  title,
  type,
  currency,
  price,
  lowestPrice,
  image,
  lowEstimate,
  highEstimate,
  estimatedRetailPrice,
  certifiedPreOwned,
  bottleSize,
  produced,
  quantity,
  isAlcoholBag,
}: CardProps) => {
  const isCertifiedPreOwned =
    certifiedPreOwned && certifiedPreOwned[0] === true;

  return (
    <div className={style.container}>
      <div className={style.imageContainer}>
        <LazyLoadImage
          src={image || ''}
          alt=""
          threshold={500}
          effect="opacity"
        />
      </div>
      {creator && (
        <Headline20Regular
          className={`${isCertifiedPreOwned ? '' : style.spacer} ${
            style.creator
          }`}
        >
          {creator}
        </Headline20Regular>
      )}

      <Paragraph16Regular
        className={`${style.title} ${
          creator ? style.twoLineClamp : style.threeLineClamp
        }`}
      >
        {title}
      </Paragraph16Regular>

      {isAlcoholBag &&
        produced &&
        (isMobileOrTablet() ? (
          <Paragraph12Regular className={style.producedIn}>
            {produced}
          </Paragraph12Regular>
        ) : (
          <Paragraph16Regular className={style.producedIn}>
            {produced}
          </Paragraph16Regular>
        ))}

      <Pricing
        type={type}
        currency={currency}
        price={price}
        lowestPrice={lowestPrice}
        estimatedRetailPrice={estimatedRetailPrice}
        lowEstimate={lowEstimate}
        highEstimate={highEstimate}
        quantity={quantity}
        bottleSize={bottleSize}
        isAlcoholBag={isAlcoholBag}
      />
      {/* Only for watches */}
      {isCertifiedPreOwned && (
        <Label12Medium className={style.certifiedPreOwnedLabel}>
          Bucherer Certified Pre-Owned
        </Label12Medium>
      )}
    </div>
  );
};

export default GridCard;
