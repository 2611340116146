import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { Paragraph14Regular } from '@sothebys/sterling/typography';

import AuthZeroStore from '../../stores/AuthZeroStore';
import { trackContentCardClicked } from '../../utils/segmentTracking';

import style from './index.module.css';

type FeaturedLinksProps = {
  links: FeaturedLink[];
  collectionTitle: string;
  language: string;
};
type FeaturedLink = {
  key: string;
  title: string;
  imageUrl: string;
  collectionUrl: string;
};
type TitleProps = {
  text: string;
  url: string;
};
type ImageProps = {
  url: string;
  label: string;
};

const Title = (props: TitleProps) => (
  <div className={style.title}>
    <Paragraph14Regular>{props.text}</Paragraph14Regular>
  </div>
);
const Image = (props: ImageProps) => (
  <div
    role="img"
    style={{ backgroundImage: `url(${props.url})` }}
    className={style.image}
    aria-label={props.label}
  ></div>
);
const FeaturedLinks = (props: FeaturedLinksProps) => {
  const [{ x }, spring] = useSpring(() => ({
    x: 0,
    config: { decay: true, frequency: 5.5 },
  }));
  const ref = React.useRef<HTMLDivElement | null>(null);
  const bind = useDrag(
    ({ offset, velocity, direction, down }) => {
      spring.start({
        x: -offset[0],
        immediate: down,
        config: {
          velocity: -direction[0] * velocity[0],
        },
      });
    },
    {
      axis: 'x',
      filterTaps: true,
      from() {
        return [-(ref?.current?.scrollLeft || 0), 0];
      },
    },
  );

  return (
    <div
      className={`${style.wrapper} ${
        props.links.length < 5 ? style.extraWrapper : ''
      }`}
    >
      <div className={style.leftGradient}></div>
      <div className={style.rightGradient}></div>
      <animated.div
        className={style.slideWrapper}
        ref={ref}
        {...bind()}
        scrollLeft={x}
      >
        <div
          className={`${style['slide']} ${
            style['slide-' + props.links.length]
          }`}
        >
          {props.links.map((link: FeaturedLink) => {
            return (
              <div className={style.frame} key={link.key}>
                <a
                  href={link.collectionUrl}
                  draggable="false"
                  onDragStart={(e) => e.preventDefault()}
                  onClick={() => {
                    trackContentCardClicked({
                      auth0_id: AuthZeroStore.getAuth0Id(),
                      cardTitle: link.title,
                      cardType: 'Product List Mosaic Tile',
                      collection: props.collectionTitle,
                      listId: props.collectionTitle,
                      language: props.language,
                    });
                  }}
                >
                  <Image url={link.imageUrl} label={link.title} />
                  <Title text={link.title} url={link.collectionUrl} />
                </a>
              </div>
            );
          })}
        </div>
      </animated.div>
    </div>
  );
};
export default FeaturedLinks;
