import React, { useEffect } from 'react';
import style from './index.module.css';
import { Media } from '../../client';
import { getCollectionImageBySize } from '../../utils/utils';

type HeroImageProps = {
  title: string;
  setImageExists: (value: boolean) => void;
  media: Media;
};

const HeroImage = ({ title, setImageExists, media }: HeroImageProps) => {
  const imageExtraLarge = getCollectionImageBySize(media, 'ExtraLarge');
  const imageLarge = getCollectionImageBySize(media, 'Large');
  const width = imageExtraLarge?.width;
  const height = imageExtraLarge?.height;
  const imageUrlExtraLarge = imageExtraLarge?.url;
  const imageUrlLarge = imageLarge?.url;
  if (width && height && imageUrlExtraLarge) {
    return (
      <div
        style={{
          paddingTop: `${height && width ? (height / width) * 100 : 0}%`,
        }}
      >
        <div className={style.imageContainer}>
          <div
            className={style.visibleDesktopHiddenMobile}
            style={{ backgroundImage: `url(${imageUrlExtraLarge})` }}
          ></div>
          <div
            className={style.visibleMobileHiddenDesktop}
            style={{ backgroundImage: `url(${imageUrlLarge})` }}
          ></div>
          <h1 className={style.title}>{title}</h1>
        </div>
      </div>
    );
  } else {
    return <HeroImageEmptyContainer setImageExists={setImageExists} />;
  }
};

type HeroImageEmptyContainerProps = {
  setImageExists: (value: boolean) => void;
};

const HeroImageEmptyContainer = ({
  setImageExists,
}: HeroImageEmptyContainerProps) => {
  useEffect(() => {
    setImageExists(false);
  }, []);

  return null;
};
export default HeroImage;
