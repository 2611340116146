import React, {
  ChangeEvent,
  WheelEvent,
  KeyboardEvent,
  useState,
  useEffect,
} from 'react';
import { Input } from '@sothebys/sterling/form';
import style from './index.module.css';
import { useCurrency } from '../../hooks/CurrencyHook';
import { ButtonLink12Regular } from '@sothebys/sterling/typography';
import { Currency } from '../../client';

export interface NumericRangeFilterState {
  newMin: number;
  newMax: number;
}

type Props = {
  onChange: (newMin: number, newMax: number) => void;
  priceFilterLowUSD?: number;
  priceFilterHighUSD?: number;
  defaultLowUSD?: number;
  defaultHighUSD?: number;
};

export const RangeInput: React.FunctionComponent<Props> = ({
  onChange,
  priceFilterLowUSD,
  priceFilterHighUSD,
  defaultHighUSD = 0,
  defaultLowUSD = 0,
}) => {
  const [statePriceLowConverted, setStatePriceLowConverted] =
    useState<number>();
  const [statePriceHighConverted, setStatePriceHighConverted] =
    useState<number>();

  const [shouldApplyFilter, setShouldApplyFilter] = useState(false);

  const currencyHook = useCurrency();

  useEffect(() => {
    setConvertedCurrency();
  }, []);

  useEffect(() => {
    if (!currencyHook.isLoading) setConvertedCurrency();
  }, [currencyHook.allCurrencies, currencyHook.isLoading]);

  useEffect(() => {
    if (shouldApplyFilter) {
      onChange(
        selectedCurrencyToUsd(statePriceLowConverted || defaultLowUSD),
        selectedCurrencyToUsd(statePriceHighConverted || defaultHighUSD),
      );
      setShouldApplyFilter(false);
    }
  }, [shouldApplyFilter]);

  const setConvertedCurrency = () => {
    setStatePriceLowConverted(
      usdToSelectedCurrency(priceFilterLowUSD || defaultLowUSD),
    );
    setStatePriceHighConverted(
      usdToSelectedCurrency(priceFilterHighUSD || defaultHighUSD),
    );
  };

  const usdToSelectedCurrency = (price: number): number => {
    return currencyHook.convertedCurrencyAmount(price, Currency.USD, false);
  };

  const selectedCurrencyToUsd = (price: number): number => {
    return currencyHook.convertedCurrencyAmount(price, Currency.USD, true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValueConverted = Number(e.currentTarget.value);
    if (e.currentTarget?.id === 'priceLow') {
      setStatePriceLowConverted(newValueConverted);
    } else {
      setStatePriceHighConverted(newValueConverted);
    }
  };

  const submit = () => {
    setShouldApplyFilter(true);
  };

  const onReset = () => {
    setStatePriceLowConverted(usdToSelectedCurrency(defaultLowUSD));
    setStatePriceHighConverted(usdToSelectedCurrency(defaultHighUSD));
    submit();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  // prevent number input change on scroll
  const numberInputOnWheelPreventChange = (e: WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
    e.stopPropagation();
  };

  return (
    <div className={style.filterContainer}>
      <form onSubmit={submit} id="priceFilterForm">
        <Input
          name="priceLow"
          label="From"
          helperLabel={currencyHook.selectedCurrency}
          inputSize="normal"
          type="number"
          showNumberSpinner={false}
          value={statePriceLowConverted?.toString() || ''}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={submit}
          onWheelCapture={numberInputOnWheelPreventChange}
        />

        <Input
          name="priceHigh"
          label="To"
          helperLabel={currencyHook.selectedCurrency}
          inputSize="normal"
          type="number"
          showNumberSpinner={false}
          value={statePriceHighConverted?.toString() || ''}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={submit}
          onWheelCapture={numberInputOnWheelPreventChange}
        />
      </form>
      <div className={style.priceFilterActions}>
        <ButtonLink12Regular onClick={onReset}>Reset</ButtonLink12Regular>
        <ButtonLink12Regular onClick={submit}>Apply</ButtonLink12Regular>
      </div>
    </div>
  );
};

export default RangeInput;
