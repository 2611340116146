import React, { useEffect, useState } from 'react';
import { Button } from '@sothebys/sterling/button';
import { Select, Item } from '@sothebys/sterling/form';
import {
  Headline20Regular,
  Headline24Regular,
  Paragraph12Regular,
  Paragraph14Regular,
  Paragraph16Regular,
  Label12Medium,
} from '@sothebys/sterling/typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { addToBag, Currency } from '../../client';
import {
  isBrowser,
  isMobileOrTablet,
  isWineSalesEntity,
  quantityLabel,
} from '../../utils/utils';
import { getGuestBagIdKey, updateCart } from '../../utils/globalCart';
import { CardType } from '../../shared/types';
import Price from './Price';
import ActionLabel from './ActionLabel';
import * as AlgoliaTypes from '../../routes/BrowsePage/AlgoliaTypes';
import appConfig from '../../config';

import style from './index.module.css';

interface ListCardProps {
  id: string;
  type: CardType;
  image?: string;
  title?: string;
  price?: number;
  lowestPrice?: number;
  currency?: Currency;
  lowEstimate?: number;
  highEstimate?: number;
  bottleSize?: string;
  produced?: string;
  quantity?: number;
  quantityInBag: number;
  guestBagId: string | null;
  token?: string;
  isLoggedIn: boolean;
  trackProductAdded: (
    loggedIn: boolean,
    chosenQuantity: number,
    user: any,
  ) => void;
  trackQuantityUpdated: (loggedIn: boolean, chosenQuantity: number) => void;
  setGuestBagId: (bagId: string) => void;
  routeToPDP: (slug?: string) => void;
  salesEntity?: AlgoliaTypes.Hit['salesEntity'];
  isAlcoholBag: boolean;
  certifiedPreOwned?: Array<boolean>;
  hasVariants?: boolean;
  user: any;
  bagIsLoading: boolean;
}

const ListCard = ({
  id,
  type,
  title,
  currency,
  price,
  lowestPrice,
  image,
  lowEstimate,
  highEstimate,
  bottleSize,
  produced,
  quantity,
  quantityInBag: quantityInBagAfterLoading,
  guestBagId,
  token,
  isLoggedIn,
  trackProductAdded,
  trackQuantityUpdated,
  setGuestBagId,
  salesEntity,
  isAlcoholBag,
  certifiedPreOwned,
  hasVariants,
  routeToPDP,
  user,
  bagIsLoading,
}: ListCardProps) => {
  const [quantityInBag, setQuantityInBag] = useState(quantityInBagAfterLoading);

  const [processing, setProcessing] = useState(false);
  const [chosenQuantity, setChosenQuantity] = useState<number>(1);
  const [availableQuantity, setAvailableQuantity] = useState<number>(
    (quantity || 0) - quantityInBag,
  );
  const [availableQuantityItems, setAvailableQuantityItems] = useState<Item[]>(
    [],
  );
  const [isInBag, setIsInBag] = useState<boolean>(quantityInBag > 0);
  const isCertifiedPreOwned =
    certifiedPreOwned && certifiedPreOwned[0] === true;

  useEffect(() => {
    setAvailableQuantity((quantity || 0) - quantityInBag);
  }, [quantityInBag]);

  useEffect(() => {
    setQuantityInBag(quantityInBagAfterLoading);
  }, [quantityInBagAfterLoading]);

  useEffect(() => {
    setAvailableQuantityItems(
      [...Array(availableQuantity)].map((_, index) => ({
        label: String(index + 1),
        value: String(index + 1),
      })),
    );
  }, [availableQuantity]);

  const addProductToBag = async () => {
    setProcessing(true);
    addToBag(id, chosenQuantity + quantityInBag, guestBagId, token)
      .then((addToBagResponse) => {
        if (addToBagResponse.anonymousBagId) {
          setGuestBagId(addToBagResponse.anonymousBagId);
          window.localStorage.setItem(
            getGuestBagIdKey(isAlcoholBag),
            addToBagResponse.anonymousBagId,
          );
        }
        setQuantityInBag(() => quantityInBag + chosenQuantity);
        setAvailableQuantity(() => availableQuantity - chosenQuantity);
        setIsInBag(() => true);
        setChosenQuantity(() => 1);
        trackProductAdded(isLoggedIn, +chosenQuantity || 1, user);
        updateCart();
      })
      .catch((error) => {
        console.error('GraphQL error:', error);
      })
      .finally(() => setProcessing(false));
  };

  return (
    <div className={style.container}>
      <div className={style.image}>
        <LazyLoadImage src={image || ''} alt="" effect="opacity" />
      </div>
      <div className={style.mainInfoBlock}>
        <div className={style.informationBlocks}>
          <div className={style.productInfo}>
            <div className={style.title}>
              {isMobileOrTablet() ? (
                <Headline20Regular>{title}</Headline20Regular>
              ) : (
                <Headline24Regular>{title}</Headline24Regular>
              )}
            </div>
            {isMobileOrTablet() ? (
              <Paragraph12Regular className={style.producedIn}>
                {produced}
              </Paragraph12Regular>
            ) : (
              <Paragraph16Regular className={style.producedIn}>
                {produced}
              </Paragraph16Regular>
            )}
            {isWineSalesEntity(salesEntity) &&
              quantity !== undefined &&
              (isMobileOrTablet() ? (
                <Paragraph12Regular
                  className={`${style.stock} ${
                    !quantity ? style.outOfStock : ''
                  }`}
                >
                  {quantityLabel(quantity)}
                </Paragraph12Regular>
              ) : (
                <Paragraph14Regular
                  className={`${style.stock} ${
                    !quantity ? style.outOfStock : ''
                  }`}
                >
                  {quantityLabel(quantity)}
                </Paragraph14Regular>
              ))}
            {/* Only for watches */}
            {isCertifiedPreOwned && (
              <Label12Medium className={style.certifiedPreOwnedLabel}>
                Bucherer Certified Pre-Owned
              </Label12Medium>
            )}
          </div>
          <div className={style.priceContainer}>
            <Price
              type={type}
              currency={currency}
              price={price}
              lowestPrice={lowestPrice}
              lowEstimate={lowEstimate}
              highEstimate={highEstimate}
              toFloat={isWineSalesEntity(salesEntity)}
            />
            <Paragraph12Regular className={style.size}>
              {bottleSize}
            </Paragraph12Regular>
            {isWineSalesEntity(salesEntity) && quantity !== undefined && (
              <Paragraph12Regular
                className={`${style.stockMobile} ${
                  !quantity ? style.outOfStock : ''
                }`}
              >
                {quantityLabel(quantity)}
              </Paragraph12Regular>
            )}
          </div>
        </div>
        {CardType.fixedPriceCard === type && (
          <div className={style.activeElements}>
            {isWineSalesEntity(salesEntity) && (
              <>
                <Paragraph14Regular>Qty</Paragraph14Regular>
                <div
                  className={style.quantitySelect}
                  onClick={(e) => e.preventDefault()}
                >
                  <Select
                    size="small"
                    disabled={!availableQuantity || bagIsLoading}
                    name="quantity"
                    placeholder="0"
                    selected={availableQuantityItems.find(
                      (item: Item) => +item.value === chosenQuantity,
                    )}
                    onValueChange={(selected: Item) => {
                      setChosenQuantity(+selected.value);
                      trackQuantityUpdated(isLoggedIn, +selected.value);
                    }}
                  >
                    <Select.Menu className={style.selectList}>
                      {availableQuantityItems.map((item: Item) => (
                        <Select.Item
                          key={item.value}
                          value={item.value}
                          label={item.label}
                        />
                      ))}
                    </Select.Menu>
                  </Select>
                </div>
              </>
            )}
            <Button
              onClick={(e) => {
                e.preventDefault();
                if (isBrowser()) {
                  if (!isWineSalesEntity(salesEntity) && isInBag) {
                    window.location.href = appConfig.cartUrl();
                  } else if (hasVariants) {
                    routeToPDP();
                  } else {
                    addProductToBag();
                  }
                }
              }}
              className={style.buyButton}
              size="regular"
              disabled={
                (isWineSalesEntity(salesEntity) && !availableQuantity) ||
                processing ||
                bagIsLoading
              }
              loading={processing}
            >
              {(!isInBag || isWineSalesEntity(salesEntity)) && 'Buy Now'}
              {isInBag && !isWineSalesEntity(salesEntity) && 'Checkout Now'}
            </Button>
          </div>
        )}
        <ActionLabel type={type} />
      </div>
    </div>
  );
};

export default ListCard;
