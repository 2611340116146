import React from 'react';
import style from './index.module.css';

type Props = {
  [key: string]: any;
};

const Background = ({ orientation, ...props }: Props) => (
  <div
    className={`${style.background} ${
      orientation === 'horizontal' ? style.horizontal : ''
    }`}
  />
);
export default Background;
