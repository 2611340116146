import React from 'react';
import { CardType } from '../../shared/types';

import Cart from '../../assets/cart.svg';
import style from './index.module.css';

interface ActionLabelProps {
  type: CardType;
}

const ActionLabel = ({ type }: ActionLabelProps) => {
  switch (type) {
    case CardType.estimatedPriceCard:
      return <p className={style.actionLabel}>Bid Now</p>;

    case CardType.fixedPriceCard:
      return (
        <div
          className={`${style.actionLabel} ${style.actionLabelAndCartContainer}`}
        >
          <img src={Cart} alt="" />
          <p className={style.labelAfterIcon}>Buy Now</p>
        </div>
      );

    case CardType.noPriceCard:
    case CardType.enquireCard:
      return <p className={style.actionLabel}>Enquire</p>;
    default:
      return <></>;
  }
};

export default ActionLabel;
