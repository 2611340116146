import React, { RefObject } from 'react';
import style from './index.module.css';
import 'rheostat/initialize';
import RefinementListFilter from '../algolia/refinement_list_filter';
import PriceRangeSliderFilter from '../algolia/price_range_filter';
import RangeSliderFilter from '../algolia/range_slider';
import SortBy from '../algolia/sort_by';
import { ClearRefinements } from '../algolia/clear_refinements';
import { RefinementProps } from '../../routes/BrowsePage';
import { facetKeyToLabel } from '../algolia/config';

type FiltersProps = {
  refinements?: RefinementProps[];
  sidebarRef?: RefObject<HTMLDivElement>;
  trackSortApplied: Function;
};

const Filters: React.FunctionComponent<FiltersProps> = ({
  refinements,
  sidebarRef,
  trackSortApplied,
}) => {
  const getRefinements = () => {
    if (refinements) {
      return refinements.map((refinement) => {
        if (refinement.type === 'Range') {
          return (
            <RangeSliderFilter
              key={refinement.attribute}
              attribute={refinement.attribute}
              refinement={refinement}
            />
          );
        } else if (refinement.type === 'List') {
          return (
            <RefinementListFilter
              key={refinement.attribute}
              attribute={refinement.attribute}
              refinement={refinement}
              showMore={true}
              showMoreLimit={1000}
              sidebarRef={sidebarRef}
            />
          );
        } else if (refinement.type === 'Price') {
          return (
            <PriceRangeSliderFilter
              key={refinement.attribute}
              attribute={refinement.attribute}
              refinement={refinement}
            />
          );
        }
      });
    } else {
      return (
        <>
          <RefinementListFilter
            attribute="waysToBuy"
            refinement={facetKeyToLabel('waysToBuy')}
            showMore={true}
          />
          <RefinementListFilter
            attribute="creators"
            refinement={facetKeyToLabel('creators')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <PriceRangeSliderFilter
            attribute="priceOrEstimates"
            refinement={facetKeyToLabel('priceOrEstimates')}
          />
          <RefinementListFilter
            attribute="gallery"
            refinement={facetKeyToLabel('gallery')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Sneaker Type"
            refinement={facetKeyToLabel('Sneaker Type')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Sneaker Model"
            refinement={facetKeyToLabel('Sneaker Model')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Special Feature"
            refinement={facetKeyToLabel('Special Feature')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="US Size"
            refinement={facetKeyToLabel('US Size')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Watch Model"
            refinement={facetKeyToLabel('Watch Model')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Ring Type"
            refinement={facetKeyToLabel('Ring Type')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Ring Size"
            refinement={facetKeyToLabel('Ring Size')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Bracelet Type"
            refinement={facetKeyToLabel('Bracelet Type')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Earring Type"
            refinement={facetKeyToLabel('Earring Type')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Necklace Type"
            refinement={facetKeyToLabel('Necklace Type')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Gender"
            refinement={facetKeyToLabel('Gender')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Year"
            refinement={facetKeyToLabel('Year')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Color"
            refinement={facetKeyToLabel('Color')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Gemstone Types"
            refinement={facetKeyToLabel('Gemstone Types')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Case Material(s)"
            refinement={facetKeyToLabel('Case Material(s)')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Occasion"
            refinement={facetKeyToLabel('Occasion')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Complication"
            refinement={facetKeyToLabel('Complication')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Movement Type"
            refinement={facetKeyToLabel('Movement Type')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Dial Color(s)"
            refinement={facetKeyToLabel('Dial Color(s)')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Bezel Material(s)"
            refinement={facetKeyToLabel('Bezel Material(s)')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Certified Pre-Owned By Bucherer"
            refinement={facetKeyToLabel('Certified Pre-Owned By Bucherer')}
            showMore={true}
            showMoreLimit={1000}
            sidebarRef={sidebarRef}
          />
          <RefinementListFilter
            attribute="Sotheby's Approved"
            refinement={facetKeyToLabel("Sotheby's Approved")}
          />
          <RefinementListFilter
            attribute="Has Container?"
            refinement={facetKeyToLabel('Has Container?')}
          />
          <RefinementListFilter
            attribute="Has Papers?"
            refinement={facetKeyToLabel('Has Papers?')}
          />
        </>
      );
    }
  };
  return (
    <div className={style.container}>
      <p className={style.ariaMessage} id="aria-filter">
        Content is automatically updated when you apply filters
      </p>
      <div className={style.clearAllWrapper}>
        <ClearRefinements />
      </div>
      <SortBy type="radio" trackSortApplied={trackSortApplied} />
      {getRefinements()}
    </div>
  );
};

export default Filters;
