import React, { useRef, useState } from 'react';
import style from './index.module.css';
import { Label } from '../';
import DropdownForm from '../../assets/dropdown_form.svg';
import { useOnClickOutside } from '../../utils/customHooks';

const getAriaLabel = (label: string) => {
  switch (label) {
    case '$ - $$$':
      return 'Price: Low – High';
    case '$$$ - $':
      return 'Price: High – Low';
    default:
      return label;
  }
};

type DropdownProps = {
  items: {
    label: string;
    selectedLabel?: string;
    value: string;
    isSelected: boolean;
    disabled?: boolean;
  }[];
  onClick: (value: string) => void;
  title?: string;
  placeholder?: string;
};

export const Dropdown: React.FunctionComponent<DropdownProps> = ({
  items = [],
  onClick,
  title,
  placeholder,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  const currentItem = items.some((item) => item.isSelected === true)
    ? items.find((item) => item.isSelected === true)
    : undefined;
  useOnClickOutside({
    ref: dropdownRef,
    handler: () => {
      setShowOptions(false);
    },
  });

  const dropdownLabel = () => {
    if (title) {
      return (
        <Label semibold>
          {title}: {currentItem?.selectedLabel || currentItem?.label}
        </Label>
      );
    } else if (currentItem) {
      return <Label>{currentItem?.selectedLabel || currentItem?.label}</Label>;
    } else if (placeholder) {
      return <Label>{placeholder}</Label>;
    }
  };
  return (
    <div className={style.wrapper} ref={dropdownRef}>
      <button
        className={style.button}
        onClick={() => setShowOptions(!showOptions)}
        aria-haspopup="true"
        aria-expanded={showOptions}
        tabIndex={0}
      >
        {dropdownLabel()}
        <img src={DropdownForm} alt="" className={style.icon} />
      </button>
      {showOptions && (
        <ul
          className={style.options}
          role="listbox"
          aria-activedescendant={items.find((item) => item.isSelected)?.value}
          placeholder={placeholder}
        >
          {items.map(({ label, value, isSelected, disabled = false }) => (
            <li
              aria-label={getAriaLabel(label)}
              tabIndex={0}
              id={value}
              className={`${style.option} ${isSelected ? style.selected : ''} ${
                disabled ? style.disabled : ''
              }`}
              role="option"
              aria-selected={isSelected}
              key={label}
              onClick={() => {
                setShowOptions(!showOptions);
                if (!isSelected && !disabled) {
                  onClick(value);
                }
              }}
              onKeyPress={(e) => {
                if (e.key == 'Enter' || e.key == ' ') {
                  setShowOptions(!showOptions);
                  if (!isSelected && !disabled) {
                    onClick(value);
                  }
                }
              }}
            >
              <Label semibold={isSelected}>{label}</Label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
