import React from 'react';
import style from './index.module.css';
import { connectPagination } from 'react-instantsearch-dom';
import _ from 'lodash';
import Dots from '../../../assets/more_horizontal.svg';
import ChevronUp from '../../../assets/chevron_up.svg';
import { Label14Medium } from '@sothebys/sterling/typography';

type PaginationComponentProps = {
  currentRefinement: number;
  nbPages: number;
  refine: any;
  createURL: any;
};

type Props = {
  page?: number;
  currentRefinement: number;
  nbPages?: number;
  refine: any;
  createURL: any;
};

const renderPrevPageLink = ({
  currentRefinement,
  refine,
  createURL,
}: Props) => {
  const page = _.max([currentRefinement - 1, 1]);
  const disabled = currentRefinement === page;
  return (
    <li
      key="prev"
      className={`${style.item} ${disabled ? style.disabled : ''}`}
    >
      <a
        className={disabled ? style.linkDisabled : ''}
        href={createURL(page)}
        onClick={(event) => {
          event.preventDefault();
          refine(page);
        }}
        aria-disabled={disabled}
      >
        <span className={`${style.vector} ${style.left}`}>
          <img
            src={ChevronUp}
            alt={disabled ? 'Previous' : `Go to previous page ${page}`}
          />
        </span>
        <div className={style.text} aria-hidden={true} role="presentation">
          <Label14Medium>Previous</Label14Medium>
        </div>
      </a>
    </li>
  );
};

const renderNextPageLink = ({
  currentRefinement,
  refine,
  createURL,
  nbPages,
}: Props) => {
  const page = _.min([currentRefinement + 1, nbPages]);
  const disabled = currentRefinement === page;
  return (
    <li
      key="next"
      className={`${style.item} ${disabled ? style.disabled : ''}`}
    >
      <a
        className={disabled ? style.linkDisabled : ''}
        href={createURL(page)}
        onClick={(event) => {
          event.preventDefault();
          refine(page);
        }}
        aria-disabled={disabled}
      >
        <div className={style.text} aria-hidden={true} role="presentation">
          <Label14Medium>Next</Label14Medium>
        </div>
        <span className={`${style.vector} ${style.right}`}>
          <img
            src={ChevronUp}
            alt={disabled ? 'Next' : `Go to next page ${page}`}
          />
        </span>
      </a>
    </li>
  );
};

const renderPageLink = ({
  page,
  currentRefinement,
  refine,
  createURL,
}: Props) => {
  return (
    <li key={page} className={style.item}>
      <a
        className={`${style.pageLink} ${
          currentRefinement === page ? style.current : ''
        }`}
        href={createURL(page)}
        onClick={(event) => {
          event.preventDefault();
          refine(page);
        }}
        aria-current={currentRefinement === page ? true : undefined}
        title={
          currentRefinement === page
            ? `Current Page ${page}`
            : `Go to page ${page}`
        }
      >
        <Label14Medium>{page}</Label14Medium>
      </a>
    </li>
  );
};

const renderDots = () => (
  <li className={style.item}>
    <img src={Dots} alt="Dots icon" />
  </li>
);

const PaginationComponent: React.FunctionComponent<
  PaginationComponentProps
> = ({ currentRefinement, nbPages, refine, createURL }) => {
  const startPos = _.min([_.max([currentRefinement, 3]), nbPages - 2]); //we use startPos if nbPages > 5, so startPos always be positive
  const panel =
    nbPages <= 5
      ? _.range(1, nbPages + 1)
      : _.range(startPos! - 1, startPos! + 2);
  return (
    <nav aria-label="Pagination">
      <ul className={style.pagination}>
        {nbPages > 1 &&
          renderPrevPageLink({
            currentRefinement,
            refine,
            createURL,
          })}
        {nbPages > 5 &&
          renderPageLink({
            page: 1,
            currentRefinement,
            refine,
            createURL,
          })}
        {nbPages > 5 && currentRefinement > 3 && renderDots()}
        {panel.map((page) =>
          renderPageLink({ page, currentRefinement, refine, createURL }),
        )}
        {nbPages > 5 && currentRefinement < nbPages - 2 && renderDots()}
        {nbPages > 5 &&
          renderPageLink({
            page: nbPages,
            currentRefinement,
            refine,
            createURL,
          })}
        {nbPages > 1 &&
          renderNextPageLink({
            currentRefinement,
            refine,
            createURL,
            nbPages,
          })}
      </ul>
    </nav>
  );
};

export const Pagination = connectPagination(PaginationComponent);
