import style from './index.module.css';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchBoxProvided } from 'react-instantsearch-core';
import Search from '../../../assets/search.svg';
import { FormEvent } from 'react';

type SearchBoxProps = SearchBoxProvided & { collectionTitle: string };

const SearchBoxComponent = ({
  currentRefinement,
  refine,
  collectionTitle,
}: SearchBoxProps) => {
  const placeholder = collectionTitle
    ? 'Search ' + collectionTitle
    : 'Search Sotheby’s';
  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
  };
  return (
    <form
      noValidate
      role="search"
      className={style.searchForm}
      onSubmit={submitHandler}
    >
      <input
        placeholder={placeholder}
        aria-label={placeholder}
        className={style.inputBox}
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
      />
      <div className={style.searchLogo}>
        <img src={Search} alt="" />
      </div>
    </form>
  );
};

const SearchBox = connectSearchBox<SearchBoxProps>(SearchBoxComponent);

export default SearchBox;
