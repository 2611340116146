interface FilterConfig {
  label?: string;
  showClearAll?: boolean;
  itemMap?: {
    [key: string]: string;
  };
}

interface KeyLabelMap {
  [key: string]: FilterConfig;
}

const keyLabelMap: KeyLabelMap = {
  'Has Container?': {
    itemMap: {
      true: 'Yes',
      false: 'No',
    },
  },
  'Has Papers?': {
    itemMap: {
      true: 'Yes',
      false: 'No',
    },
  },
  "Sotheby's Approved": {
    itemMap: {
      true: 'Yes',
      false: 'No',
    },
  },
  Complication: { label: 'Complication' },
  gallery: { label: 'Gallery' },
  creators: { label: 'Creator' },
  waysToBuy: {
    label: 'Ways To Buy',
    itemMap: {
      bid: 'Bid Now',
      buyNow: 'Buy Now',
      private: 'Private Sale',
    },
  },
  priceOrEstimates: { label: 'Price / Estimate' },
  'Certified Pre-Owned By Bucherer': {
    label: 'Features',
    itemMap: {
      true: 'Certified by Bucherer',
    },
  },
};

export const facetKeyToLabel = (key: string) => {
  return keyLabelMap[key] || {};
};
