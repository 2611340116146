import React from 'react';
import { Button14Medium } from '@sothebys/sterling/typography';
import { Collection24 } from '@sothebys/sterling/icons';

import style from './index.module.css';
import { trackViewTypeSelected } from '../../utils/segment/ViewTypeSelected';

interface CollectionViewSwitcherProps {
  showButtons: boolean;
  isGridView: boolean;
  isMobile?: boolean;
  setIsGridView: (isGridView: boolean) => void;
}

const CollectionViewSwitcher = ({
  showButtons,
  isGridView,
  setIsGridView,
  isMobile = false,
}: CollectionViewSwitcherProps) => {
  return showButtons ? (
    <div className={`${style.container} ${isMobile ? '' : style.desktop}`}>
      <button
        onClick={() => {
          trackViewTypeSelected({
            viewSelection: 'Grid',
            pageName: 'Marketplace',
          });
          setIsGridView(true);
        }}
        className={`${style.button} ${isGridView ? style.active : ''}`}
      >
        <Button14Medium>
          <Collection24.CardViewFour24 />
        </Button14Medium>
      </button>
      <button
        onClick={() => {
          trackViewTypeSelected({
            viewSelection: 'List',
            pageName: 'Marketplace',
          });
          setIsGridView(false);
        }}
        className={`${style.button} ${isGridView ? '' : style.active}`}
      >
        <Button14Medium>
          <Collection24.ListViewWide24 />
        </Button14Medium>
      </button>
    </div>
  ) : null;
};

export default CollectionViewSwitcher;
