import React from 'react';
import ListCard from '../../../../components/list_card';
import GridCard from '../../../../components/grid_card';
import * as AlgoliaTypes from '../../AlgoliaTypes';
import { CardType } from '../../../../shared/types';
import { User } from '../../../../auth';
import { Currency } from '../../../../client';
import trackingStore from '../../../../stores/TrackingStore';

import {
  mapHitToProductAdded,
  trackProductAdded,
} from '../../../../utils/segment/ProductAdded';
import {
  mapHitToQuantityUpdated,
  trackQuantityUpdated,
} from '../../../../utils/segment/QuantityUpdated';
import Link, { CustomNextLinkProps } from '../../../../components/link';

interface CollectionPageCardProps {
  isGridView: boolean;
  index: number;
  hit: AlgoliaTypes.Hit;
  host: string;
  path: string;
  linkData: CustomNextLinkProps;
  id?: string;
  isBidItem?: boolean;
  type: CardType;
  image: string;
  price?: number;
  lowestPrice?: number;
  estimatedRetailPrice?: number;
  title?: string;
  creator?: string;
  lowEstimate?: number;
  highEstimate?: number;
  currency?: Currency;
  bottleSize?: string;
  produced: string;
  quantity: number;
  certifiedPreOwned?: boolean[];
  isAlcoholBag: boolean;
  quantityInBag: number;
  guestBagId: string | null;
  token?: string;
  isLoggedIn: boolean;
  setGuestBagId: (bagId: string) => void;
  routeToPDP: (slug?: string) => void;
  salesEntity?: AlgoliaTypes.Hit['salesEntity'];
  hasVariants?: boolean;
  user?: User;
  bagIsLoading: boolean;
}

const CollectionPageCard = ({
  isGridView,
  index,
  hit,
  host,
  path,
  linkData,
  id,
  isBidItem,
  type,
  image,
  price,
  lowestPrice,
  estimatedRetailPrice,
  title,
  creator,
  lowEstimate,
  highEstimate,
  currency,
  bottleSize,
  produced,
  quantity,
  certifiedPreOwned,
  isAlcoholBag,
  quantityInBag,
  guestBagId,
  token,
  isLoggedIn,
  setGuestBagId,
  routeToPDP,
  salesEntity,
  hasVariants,
  user,
  bagIsLoading,
}: CollectionPageCardProps) => {
  const productAdded = (
    loggedIn: boolean,
    chosenQuantity: number,
    user: any,
  ) => {
    trackProductAdded({
      retailItem: mapHitToProductAdded(hit),
      chosenQuantity,
      host,
      path,
      loggedIn,
      user,
    });
  };
  const quantityUpdated = (loggedIn: boolean, chosenQuantity: number) => {
    trackQuantityUpdated({
      retailItem: mapHitToQuantityUpdated(hit),
      chosenQuantity,
      loggedIn,
    });
  };

  const card = (
    <Link
      href={linkData.href}
      as={linkData.as}
      id={`tilePositionIndex=${index}`}
      routeOutsideApp={isBidItem}
    >
      {isGridView ? (
        <GridCard
          type={type}
          id={id}
          image={image}
          title={title}
          creator={creator}
          lowEstimate={lowEstimate}
          highEstimate={highEstimate}
          currency={currency}
          bottleSize={bottleSize}
          produced={produced}
          price={price}
          lowestPrice={lowestPrice}
          estimatedRetailPrice={estimatedRetailPrice}
          quantity={quantity}
          certifiedPreOwned={certifiedPreOwned}
          isAlcoholBag={isAlcoholBag}
        />
      ) : (
        <ListCard
          id={id || ''}
          type={type}
          image={image}
          title={title}
          price={price}
          lowestPrice={lowestPrice}
          lowEstimate={lowEstimate}
          highEstimate={highEstimate}
          currency={currency}
          bottleSize={bottleSize}
          produced={produced}
          quantity={quantity}
          quantityInBag={quantityInBag}
          guestBagId={guestBagId}
          token={token}
          isLoggedIn={isLoggedIn}
          trackProductAdded={productAdded}
          trackQuantityUpdated={quantityUpdated}
          setGuestBagId={setGuestBagId}
          routeToPDP={routeToPDP}
          salesEntity={salesEntity}
          isAlcoholBag={isAlcoholBag}
          certifiedPreOwned={certifiedPreOwned}
          hasVariants={hasVariants}
          user={user}
          bagIsLoading={bagIsLoading}
        />
      )}
    </Link>
  );

  if (isBidItem) return card;

  return (
    <div
      onClick={() =>
        trackingStore.setState({
          ...trackingStore.getState(),
          algoliaObjectID: hit.objectID,
        })
      }
    >
      {card}
    </div>
  );
};

export default CollectionPageCard;
