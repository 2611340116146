import React from 'react';
import style from './index.module.css';
import { connectSortBy } from 'react-instantsearch-dom';
import Radio from '../../radio';
import { Dropdown } from '../../../atoms/dropdown';
import appConfig from '../../../config';
import { ClearRefinements } from '../clear_refinements';

export const getSortByItems = () => {
  const tier = appConfig?.tier();
  return [
    {
      value: `${tier}_product_items`,
      label: 'Relevance',
    },
    {
      value: `${tier}_product_items_lowEstimate_asc`,
      label: '$ - $$$',
    },
    {
      value: `${tier}_product_items_lowEstimate_desc`,
      label: '$$$ - $',
    },
    {
      value: `${tier}_product_items_publishDate_desc`,
      label: 'Newest',
    },
  ];
};

export const getDefaultSortByItem = (): string => getSortByItems()[0].value;

type SortByRadioProps = {
  refine: (value: string) => any;
  items: Array<{ isRefined: boolean; label: string; value: string }>;
  trackSortApplied: Function;
};

const SortByRadioComponent = ({
  items,
  refine,
  trackSortApplied,
}: SortByRadioProps) => (
  <div className={style.radioWrapper}>
    <ul className={style.options}>
      {items.map((item) => (
        <li key={item.label}>
          <Radio
            label={item.label}
            checked={item.isRefined}
            onChange={() => {
              trackSortApplied(item.label);
              refine(item.value);
            }}
            name="sortBy"
          />
        </li>
      ))}
    </ul>
  </div>
);

const SortByRadio = connectSortBy(SortByRadioComponent);

const SortByDropdownComponent = ({
  items,
  refine,
  trackSortApplied,
}: SortByRadioProps) => {
  const dropdownItems = items.map((item) => ({
    ...item,
    isSelected: item.isRefined,
  }));

  return (
    <div className={style.dropdownWrapper}>
      <Dropdown
        title="Sort By"
        items={dropdownItems}
        onClick={(value: string) => {
          trackSortApplied(
            items.find((item) => item.value === value)?.label || '',
          );
          refine(value);
        }}
      />
    </div>
  );
};

type SortByProps = {
  type: 'radio' | 'dropdown';
  trackSortApplied: Function;
};

const SortBy = ({ type, trackSortApplied }: SortByProps) => {
  return type === 'radio' ? (
    <div className={style.sortByWrapper}>
      <div className={style.clearAllWrapper}>
        <ClearRefinements />
      </div>
      <div className={style.accordionHeader}>Sort By</div>{' '}
      <SortByRadio
        items={getSortByItems()}
        defaultRefinement={getDefaultSortByItem()}
        trackSortApplied={trackSortApplied}
      />
    </div>
  ) : (
    <SortByDropdown
      items={getSortByItems()}
      defaultRefinement={getDefaultSortByItem()}
      trackSortApplied={trackSortApplied}
    />
  );
};

const SortByDropdown = connectSortBy(SortByDropdownComponent);

export default SortBy;
