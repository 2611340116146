import { viewTypeSelected } from '../../../../analytics';
import { isBrowser } from '../utils';

export type TrackViewTypeSelectedProps = {
  pageName?: 'Auction Page' | 'Lot Page' | 'Marketplace';
  viewSelection: 'Grid' | 'List';
};

export const trackViewTypeSelected = ({
  viewSelection,
  pageName,
}: TrackViewTypeSelectedProps) => {
  if (isBrowser()) {
    const props = {
      view_selection: viewSelection,
      page_name: pageName,
    };

    viewTypeSelected(props);
  }
};
