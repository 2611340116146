import Checkbox from '../../../checkbox';
import React, { useState, useEffect, useRef } from 'react';
import { Paragraph14Regular } from '@sothebys/sterling/typography';

export type RefinementItem = {
  label: string;
  value: string[];
  count: number;
  isRefined: boolean;
  isCurrent?: boolean;
};

interface RenderOptionsListProps {
  listExpanded: boolean;
  listExpandedMobile: boolean;
  filteredItems: RefinementItem[];
  itemsToShow?: number;
  refine: (value: string[]) => any;
  optionsClassName: string;
  gradientClassName: string;
}

const OptionsList = ({
  listExpanded,
  listExpandedMobile,
  filteredItems,
  itemsToShow,
  refine,
  optionsClassName,
  gradientClassName,
}: RenderOptionsListProps) => {
  const [showGradient, setShowGradient] = useState(false);
  const optionsListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if ((listExpanded || listExpandedMobile) && optionsListRef.current) {
      setShowGradient(
        optionsListRef.current.scrollHeight >
          optionsListRef.current.clientHeight,
      );
    } else {
      setShowGradient(false);
    }
  }, [listExpanded, listExpandedMobile, filteredItems]);

  const handleScroll = ({
    currentTarget: { scrollHeight, scrollTop, clientHeight },
  }: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const scrolledToTheBot = scrollHeight - scrollTop === clientHeight;
    if (showGradient === true && scrolledToTheBot === true) {
      setShowGradient(false);
    } else if (showGradient === false && scrolledToTheBot === false) {
      setShowGradient(true);
    }
  };

  return (
    <ul
      ref={optionsListRef}
      className={`${optionsClassName} ${showGradient ? gradientClassName : ''}`}
      onScroll={handleScroll}
    >
      {filteredItems
        .slice(0, itemsToShow ? itemsToShow : filteredItems.length)
        .map((item) => {
          const countLabel = ` (${item.count})`;
          if (item.label !== '') {
            return (
              <li key={item.label}>
                <Checkbox
                  label={item.label + countLabel}
                  checked={item.isRefined}
                  onChange={() => {
                    refine(item.value);
                  }}
                  disabled={!item.isCurrent}
                />
              </li>
            );
          }
        })
        .filter((item) => {
          // remove null, undefined or empty string (i.e. falsy) instances since if label is set to "" we don't render a checkbox
          return item;
        })}
      {filteredItems.length === 0 && (
        <Paragraph14Regular>No results</Paragraph14Regular>
      )}
    </ul>
  );
};

export default OptionsList;
