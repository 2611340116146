import React from 'react';
import style from './index.module.css';

type Props = {
  onChange?: () => void;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
};

const Checkbox = ({ label, checked, onChange, disabled }: Props) => (
  <label
    className={`${style.label} ${disabled ? style.disabled : ''}`}
    aria-disabled={disabled}
  >
    {label}
    <input
      aria-describedby="aria-filter"
      className={style.checkboxInput}
      type="checkbox"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
    <span
      className={`${style.checkmark} ${
        disabled ? style.checkmarkDisabled : ''
      }`}
    ></span>
  </label>
);
export default Checkbox;
