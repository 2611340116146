import appConfig from '../config';

export enum DamAssetType {
  Auction = 'Auction',
  Lot = 'Lot',
  Item = 'Item',
  Creator = 'Creator',
}

export enum DamAssetRendition {
  ExtraSmall = 'extra_small',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extra_large',
}

export const getPrimaryImageUrl = (
  assetType: DamAssetType,
  vikingId: string,
  rendition: DamAssetRendition,
) =>
  `${appConfig.damUrl()}/dam/image/${assetType}/${vikingId}/primary/${rendition}`;

export const getRetailItemPrimaryImageUrl = (
  retailItemId: string,
  rendition: DamAssetRendition,
) => getPrimaryImageUrl(DamAssetType.Item, retailItemId, rendition);

export const getLotPrimaryImageUrl = (
  lotId: string,
  rendition: DamAssetRendition,
) => getPrimaryImageUrl(DamAssetType.Lot, lotId, rendition);

export const getMerchandisingTileImageUrl = (
  tileId: string,
  rendition: DamAssetRendition,
) =>
  getPrimaryImageUrl(
    DamAssetType.Item,
    `collection-merchandisingTile_${tileId}`,
    rendition,
  );
