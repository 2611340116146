import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { SearchClient } from 'algoliasearch/lite';
import {
  Configure,
  connectHits,
  InstantSearch,
  Stats,
} from 'react-instantsearch-dom';
import { HitsProvided } from 'react-instantsearch-core';
import { useSwipeable } from 'react-swipeable';
import qs from 'qs';
import _ from 'lodash';
import {
  Button14Medium,
  Paragraph14Regular,
  Paragraph18Regular,
  Headline32Regular,
} from '@sothebys/sterling/typography';

import { Hierarchy, HierarchyItem } from '../../atoms';
import { getTokens } from '../../utils/strUtils';
import { Pagination } from '../../components/algolia/pagination';
import CollectionHeader from '../../components/collection_header';
import CollectionViewSwitcher from '../../components/collection_view_switcher';
import CurrencyConverter from '../../components/currency_converter';
import FeaturedLinks from '../../components/featured_links';
import Filter from '../../assets/filter.svg';
import Filters from '../../components/filters';
import HeroImage from '../../components/hero_image';
import CollectionPageCard from './components/collection_page_card';
import LoginModal from '../../components/login_modal';
import ScrollTo from '../../components/algolia/scroll_to';
import SearchBox from '../../components/algolia/search_field';
import SortBy from '../../components/algolia/sort_by';
import style from './index.module.css';

import AuthZeroStore, { State, TokenState } from '../../stores/AuthZeroStore';
import Close from '../../assets/close.svg';
import appConfig from '../../config';
import { useAuthentication } from '../../hooks/AuthenticationHook';
import {
  Media,
  Collection,
  MerchandisingTile,
  getBagById,
  getBagsForLoggedInUser,
  mergeBags,
  getCollectionParentPath,
} from '../../client';
import { CardType } from '../../shared/types';
import {
  getQueryString,
  getCurrentPath,
  getCurrentPathname,
  generateCreatorsString,
  isBrowser,
  isWineSalesEntity,
} from '../../utils/utils';
import {
  DamAssetRendition,
  getLotPrimaryImageUrl,
  getMerchandisingTileImageUrl,
  getRetailItemPrimaryImageUrl,
} from '../../utils/damUtils';
import {
  GUEST_CART_BAGID_KEY,
  GUEST_ALCOHOL_CART_BAGID_KEY,
  updateCart,
} from '../../utils/globalCart';
import { replaceAll } from '../../utils/strUtils';

import AlgoliaConnectAnalytics from './AlgoliaConnectAnalytics';
import * as AlgoliaTypes from './AlgoliaTypes';
import * as BrowsePageUtils from './utils';
import { clearGuestBagId, clearAlcoholGuestBagId } from '../../utils/guestBag';
import { trackProductListFiltered } from '../../utils/segmentTracking';

const getHierarchy = (path: string, language: string) => {
  // don't include '/shop-all' in collection page URLs
  const splitPath = getTokens(path).filter((path) => path !== 'shop-all');

  let hierarchyCollectionLinks = _.map(
    splitPath,
    (item: string, index: number) => ({
      label: replaceAll(item, '-', ' '),
      query: {
        collectionSlug: _.join(_.take(splitPath, index + 1), '/').toLowerCase(),
        language,
      },
      href: `/${language}/buy/${_.join(
        _.take(splitPath, index + 1),
        '/',
      ).toLowerCase()}`,
      pathname: '/BrowsePage',
      isLink: false,
    }),
  );

  const shopAllCollectionLink = {
    label: 'Shop All',
    query: {
      collectionSlug: 'shop-all',
      language,
    },
    href: '/en/buy/shop-all',
    pathname: '/BrowsePage',
    isLink: false,
  };

  hierarchyCollectionLinks.unshift(shopAllCollectionLink);

  return hierarchyCollectionLinks;
};

export const algoliaHierarchyToPath = (hierarchy?: {
  [key: string]: string;
}) => {
  if (hierarchy === undefined || _.isEmpty(hierarchy)) {
    return '';
  }
  const keys = _.keys(hierarchy);
  const keyLevels = _.map(keys, (key) => _.trimStart(key, 'lvl'));
  const maxKey = _.max(keyLevels);
  const tokens = _.split(hierarchy[`lvl${maxKey}`], ' > ');
  return `${_.join(tokens, '/').toLowerCase()}/`;
};

type CustomConnectHitProps = HitsProvided<AlgoliaTypes.Hit> & {
  callbackFocusFunction: (f: () => void) => void;
  setShowLoginModal: (showLoginModal: boolean) => void;
  isGridView: boolean;
  collectionSlug: string;
  authState: State;
  host: string;
  path: string;
  language: string;
  locale: string;
  currentPathname: string;
};

const VatInfoLink = () => (
  <Paragraph14Regular>
    Prices shown do not include taxes and duty fees.{' '}
    <a
      href="https://help.sothebys.com/en/support/solutions/articles/44002430313-what-taxes-do-i-pay-on-my-purchase-"
      target="_blank"
      rel="noopener noreferrer"
      aria-label={'Prices shown do not include taxes and duty fees. Read More'}
      className={style.vatLink}
    >
      Read More
    </a>
  </Paragraph14Regular>
);

const Hits: React.FC<CustomConnectHitProps> = ({
  hits,
  callbackFocusFunction,
  isGridView,
  authState,
  host,
  path,
  locale,
  currentPathname,
}) => {
  const containerRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      callbackFocusFunction(() => {
        containerRef.current?.focus();
      });
    }
  }, [containerRef]);
  const router = useRouter();
  const isLoggedIn = authState.tokenState === TokenState.AUTHENTICATED;
  const token = authState.token?.accessToken;
  let [guestBagId, setGuestBagId] = useState<string | null>(
    !token && isBrowser()
      ? window.localStorage?.getItem(GUEST_CART_BAGID_KEY)
      : null,
  );
  let [guestAlcoholBagId, setGuestAlcoholBagId] = useState<string | null>(
    !token && isBrowser()
      ? window.localStorage?.getItem(GUEST_ALCOHOL_CART_BAGID_KEY)
      : null,
  );

  const [itemsInTheCart, setItemsInTheCart] = useState<{
    [key: string]: number;
  }>(() => ({}));

  const [bagIsLoading, setBagIsLoading] = useState(false);

  let getCardTypeFromHit = (hit: AlgoliaTypes.Hit) => {
    if (hit.waysToBuy === 'bid') {
      return CardType.estimatedPriceCard;
    } else if (hit.waysToBuy === 'private' || hit.listPrice === 0) {
      return CardType.noPriceCard;
    } else if (hit.enquireEnabled) {
      return CardType.enquireCard;
    } else {
      return CardType.fixedPriceCard;
    }
  };

  useEffect(() => {
    const loadBag = async () => {
      setBagIsLoading(() => true);
      if (isLoggedIn && (guestBagId || guestAlcoholBagId)) {
        Promise.all([
          guestBagId
            ? mergeBags(authState.token?.accessToken || '', guestBagId)
            : null,
          guestAlcoholBagId
            ? mergeBags(authState.token?.accessToken || '', guestAlcoholBagId)
            : null,
        ]).then(() => {
          clearGuestBagId();
          clearAlcoholGuestBagId();
          updateCart();
          setBagIsLoading(() => false);
        });
      } else if (isLoggedIn) {
        //
        getBagsForLoggedInUser(token).then((response) => {
          if (response?.bags) {
            setItemsInTheCart(() => {
              const retailIdToQuantityMap: { [key: string]: number } = {};
              response.bags?.defaultBag?.items.forEach((item) => {
                retailIdToQuantityMap[item.retailItemId] = item.quantity;
              });
              response.bags?.alcoholBag?.items.forEach((item) => {
                retailIdToQuantityMap[item.retailItemId] = item.quantity;
              });
              return retailIdToQuantityMap;
            });
          }
          setBagIsLoading(() => false);
        });
      } else if (guestBagId || guestAlcoholBagId) {
        // Check if a guest has item in anonymous cart
        Promise.all([
          guestBagId ? getBagById(guestBagId) : null,
          guestAlcoholBagId ? getBagById(guestAlcoholBagId) : null,
        ]).then(([defaultBagResponse, alcoholBagResponse]) => {
          setItemsInTheCart(() => {
            const retailIdToQuantityMap: { [key: string]: number } = {};
            defaultBagResponse?.bagById?.items.forEach((item) => {
              retailIdToQuantityMap[item.retailItemId] = item.quantity;
            });
            alcoholBagResponse?.bagById?.items.forEach((item) => {
              retailIdToQuantityMap[item.retailItemId] = item.quantity;
            });
            return retailIdToQuantityMap;
          });
          setBagIsLoading(() => false);
        });
      }
      setBagIsLoading(() => false);
    };

    loadBag();
  }, [isLoggedIn, guestBagId]);

  if (hits.length) {
    return (
      <ul
        className={`${isGridView ? 'gridContent' : 'listContent'} ${
          style.hitsContainer
        }`}
        tabIndex={-1}
        ref={containerRef}
      >
        {hits.map((hit: AlgoliaTypes.Hit, index: number) => {
          const certifiedPreOwned = hit['Certified Pre-Owned By Bucherer'];
          const isAlcoholBag = isWineSalesEntity(hit.salesEntity);

          const linkData = BrowsePageUtils.getHitLinkToPDP(
            hit,
            locale,
            currentPathname,
          );

          const routeToPDP = () => {
            if (linkData) {
              router.push(linkData.href, linkData.as);
            }
          };
          let cardType = getCardTypeFromHit(hit);

          const creator = !BrowsePageUtils.isSneakersHit(hit.objectTypes)
            ? generateCreatorsString(hit.attribution, hit.creators)
            : undefined;

          const produced = [hit['Country']?.[0], hit['Region']?.[0]]
            .filter((place) => !!place)
            .join(': ');

          const quantity = hit.availableQuantity || 0;

          const isBidItem = hit.waysToBuy === 'bid';
          const isPrivateItem =
            hit.waysToBuy === 'private' || hit.listPrice === 0;
          const isRetailItem = !isBidItem && !isPrivateItem;

          return (
            <li
              key={`item-algolia-${hit.objectID}`}
              className={`colMediumSpan4 colSmallSpan2 ${
                isGridView ? style.cardContainer : style.listContainer
              }`}
            >
              <CollectionPageCard
                isGridView={isGridView}
                index={index}
                hit={hit}
                host={host}
                path={path}
                linkData={linkData}
                id={isGridView ? hit.id : hit.objectID}
                isBidItem={isBidItem}
                type={
                  isBidItem
                    ? CardType.estimatedPriceCard
                    : isPrivateItem
                    ? CardType.noPriceCard
                    : cardType
                }
                image={
                  isBidItem
                    ? getLotPrimaryImageUrl(
                        hit.objectID,
                        DamAssetRendition.Medium,
                      )
                    : getRetailItemPrimaryImageUrl(
                        hit.objectID,
                        DamAssetRendition.Medium,
                      )
                }
                price={isRetailItem ? hit.listPrice : undefined}
                lowestPrice={isRetailItem ? hit.lowestPrice : undefined}
                estimatedRetailPrice={
                  isRetailItem ? hit.estimatedRetailPrice : undefined
                }
                title={hit.title}
                creator={creator}
                lowEstimate={isBidItem ? hit.lowEstimate : undefined}
                highEstimate={isBidItem ? hit.highEstimate : undefined}
                currency={!isPrivateItem ? hit.currency : undefined}
                bottleSize={hit['Spirit Bottle Size']?.[0]}
                produced={produced}
                quantity={quantity}
                certifiedPreOwned={certifiedPreOwned}
                isAlcoholBag={isAlcoholBag}
                quantityInBag={itemsInTheCart[hit.objectID] || 0}
                guestBagId={isAlcoholBag ? guestAlcoholBagId : guestBagId}
                token={token}
                isLoggedIn={isLoggedIn}
                setGuestBagId={
                  isAlcoholBag ? setGuestAlcoholBagId : setGuestBagId
                }
                routeToPDP={routeToPDP}
                salesEntity={hit.salesEntity}
                hasVariants={
                  hit?.retailItemGroup?.variants &&
                  hit?.retailItemGroup?.variants?.length > 1
                }
                user={authState.token?.user}
                bagIsLoading={bagIsLoading}
              />
            </li>
          );
        })}
      </ul>
    );
  } else {
    return (
      <div aria-live={'polite'} className={style.noResultsWrapper}>
        <Paragraph18Regular>
          Sorry, there are no results. Please try modifying your search.
        </Paragraph18Regular>
      </div>
    );
  }
};

const CustomHits = connectHits<CustomConnectHitProps, AlgoliaTypes.Hit>(Hits);

enum SidebarVisibility {
  VISIBLE_DESKTOP = 'visibleDesktop',
  VISIBLE_MOBILE_DESKTOP = 'visibleMobileDesktop',
  HIDDEN_MOBILE_DESKTOP = 'hiddenMobileDesktop',
}

export interface BrowsePageProps {
  onSearchStateChange: (s: any) => void;
  searchState: any;
  resultsState: any;
  indexName: string;
  searchClient: SearchClient;
  algoliaQuery: string;
  path: string;
  host: string;
  language: string;
  collectionSlug: string;
  collectionTitle: string;
  heroDescription: string;
  media?: Media;
  collection: Collection;
  allFacetsFromAlgolia: { [key: string]: string[] };
  children?: React.ReactNode;
}

export interface RefinementProps {
  label: string;
  type: 'List' | 'Price' | 'Range';
  attribute: string;
  itemMap?: {
    [key: string]: string;
  };
  collapsed?: boolean;
}

type AlgoliaQueryProps = {
  filters?: string;
  _tags?: string[];
  refinements?: RefinementProps[];
};

const BrowsePage = ({
  onSearchStateChange,
  searchState,
  resultsState,
  indexName,
  searchClient,
  algoliaQuery,
  path,
  host,
  language,
  collectionTitle,
  heroDescription,
  collectionSlug,
  media,
  collection,
  allFacetsFromAlgolia,
  ...props
}: BrowsePageProps) => {
  const authState = useAuthentication('BrowsePage');
  const [showLoginModal, setShowLoginModal] = useState(false);
  //To prevent focusing on the first hit when page loads
  const [initialRender, setInitialRender] = useState(true);
  const [isGridView, setIsGridView] = useState(collectionSlug !== 'wine');

  const [sidebarVisibility, __updateSidebarVisibility] =
    useState<SidebarVisibility>(SidebarVisibility.VISIBLE_DESKTOP);
  const setSidebarVisibility = (visibility: SidebarVisibility) => {
    setInitialRender(false);
    __updateSidebarVisibility(visibility);
  };

  const focusContentContainerFunctionRef = useRef({ cb: () => {} });

  useEffect(() => {
    if (
      sidebarVisibility === SidebarVisibility.VISIBLE_DESKTOP &&
      !initialRender
    ) {
      focusContentContainerFunctionRef.current?.cb();
    }
  }, [sidebarVisibility, focusContentContainerFunctionRef, initialRender]);

  const toggleSidebarVisibility = () => {
    if (
      sidebarVisibility === SidebarVisibility.VISIBLE_DESKTOP ||
      sidebarVisibility === SidebarVisibility.VISIBLE_MOBILE_DESKTOP
    ) {
      setSidebarVisibility(SidebarVisibility.HIDDEN_MOBILE_DESKTOP);
    } else {
      setSidebarVisibility(SidebarVisibility.VISIBLE_DESKTOP);
    }
  };

  const getSidebarClassName = () => {
    if (sidebarVisibility === SidebarVisibility.VISIBLE_DESKTOP) {
      return style.visibleDesktop;
    } else if (sidebarVisibility === SidebarVisibility.HIDDEN_MOBILE_DESKTOP) {
      return style.hiddenMobileDesktop;
    } else {
      return style.visibleMobileDesktop;
    }
  };

  useEffect(() => {
    const lockBodyScroll = () => {
      document.body.className += ' ' + style.lockBodyScroll;
    };
    const unlockBodyScroll = () => {
      document.body.className = document.body.className.replace(
        style.lockBodyScroll,
        '',
      );
    };
    if (sidebarVisibility === SidebarVisibility.VISIBLE_MOBILE_DESKTOP) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
    return () => unlockBodyScroll();
  }, [sidebarVisibility]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSidebarVisibility(SidebarVisibility.VISIBLE_DESKTOP);
    },
    onSwipedRight: () => {
      setSidebarVisibility(SidebarVisibility.VISIBLE_MOBILE_DESKTOP);
    },
    preventScrollOnSwipe: false,
    trackMouse: false,
    delta: 40,
  });

  const { filters, _tags, refinements }: AlgoliaQueryProps =
    JSON.parse(algoliaQuery);

  // Rule context that is passed to algolia which might apply filters,
  // pin/hide items, etc. if there's a matching rule
  const combinedCollectionSlug =
    BrowsePageUtils.formatAlgoliaCollectionSlug(collectionSlug);
  const ruleContext: string = `${language}_${combinedCollectionSlug}`;
  const [hierarchy, setHierarchy] = useState<HierarchyItem[]>();

  const [imageExists, setImageExists] = useState(media ? true : false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  //Need to change sidebar visibility so focus trap is not enabled
  //when resizing to Desktop
  useEffect(() => {
    if (isBrowser()) {
      const checkSize = () => {
        if (window.innerWidth > 769) {
          setSidebarVisibility(SidebarVisibility.VISIBLE_DESKTOP);
        }
      };
      window.addEventListener('resize', checkSize);
      return () => window.removeEventListener('resize', checkSize);
    }
  }, []);

  //Focus trap inside the mobile sidebar filter for accessibility (screen readers)
  useEffect(() => {
    if (isBrowser() && sidebarRef.current) {
      const focusableElements =
        'button, input, [tabindex]:not([tabindex="-1"])';
      const focusableContent =
        sidebarRef.current.querySelectorAll(focusableElements);
      const firstFocusableElement = focusableContent[0];
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1];

      let focusTrap = (e: KeyboardEvent) => {
        let isTabPressed = e.key === 'Tab' || e.key === '9';

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            (lastFocusableElement as HTMLElement)?.focus();
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            (firstFocusableElement as HTMLElement)?.focus();
            e.preventDefault();
          }
        }
      };

      if (sidebarVisibility === SidebarVisibility.VISIBLE_MOBILE_DESKTOP) {
        document.addEventListener('keydown', focusTrap);
      } else {
        if (!initialRender) {
          focusContentContainerFunctionRef.current?.cb();
        }
        document.removeEventListener('keydown', focusTrap);
      }
      return () => document.removeEventListener('keydown', focusTrap);
    }
  }, [sidebarRef, sidebarVisibility, initialRender]);

  useEffect(() => {
    let hierarchy = getHierarchy(path, language);

    setHierarchy(hierarchy);

    if (hierarchy) {
      getCollectionParentPath(path, language).then((result) => {
        const newHierarchy = hierarchy.map((level) => {
          const existingCollections = result?.collectionParentPath?.map(
            (collection) => collection.collectionPath,
          );
          const collectionExists =
            level.query.collectionSlug !== 'shop-all'
              ? existingCollections?.includes(level.query.collectionSlug)
              : true;

          if (collectionExists) level.isLink = true;

          return level;
        });
        setHierarchy(newHierarchy);
      });
    }
  }, [path]);

  useEffect(() => {
    setImageExists(media ? true : false);
  }, [media]);

  const hasRefinement = (
    refinements: RefinementProps[] | undefined,
    facet: String,
  ) => {
    return refinements?.some((refinement) => refinement.attribute === facet);
  };

  const getFeaturedLinksFromTiles = (merchandisingTiles: MerchandisingTile[]) =>
    merchandisingTiles == null
      ? []
      : merchandisingTiles
          .sort((a, b) => a.placementIndex - b.placementIndex)
          .map((tile) => {
            return {
              key: tile.id,
              imageUrl: getMerchandisingTileImageUrl(
                tile.id,
                DamAssetRendition.Small,
              ),
              collectionUrl: tile.url,
              ...tile,
            };
          });

  const getFacets = (filters: String | undefined) => {
    // Facets that are required for analytics
    const allFacets = [
      'objectTypes',
      'department',
      'categories.lvl0',
      'categories.lvl1',
      'categories.lvl2',
      'categories.lvl3',
    ];
    const filteredFacets: String[] = [];

    // Remove facets that are already in the filters string to avoid conflict
    allFacets.map((facet) => {
      if (!filters?.includes(facet) && !hasRefinement(refinements, facet)) {
        filteredFacets.push(facet);
      }
    });

    return filteredFacets;
  };

  const trackSortApplied = (sortType: string | undefined) => {
    const results = resultsState.rawResults?.[0];

    const appliedFilters = Object.keys(searchState.refinementList).map(
      (key) => {
        let refinementValue = searchState.refinementList[key];
        if (refinementValue instanceof Array && refinementValue.length > 0) {
          refinementValue = refinementValue.join(', ');
        }
        return { attribute: key, value: refinementValue };
      },
    );

    const objectIDs = results.hits?.map(
      (hit: { objectID: any }) => hit.objectID,
    );

    trackProductListFiltered({
      collection: collectionTitle,
      filter_applied: sortType || '',
      filter_widget_type: 'dropdown',
      search_keyword: '',
      appliedFilters: appliedFilters,
      num_search_results: results?.nbHits,
      currentPage: 0, // we always send user back to first page after sorting
      totalPages: results?.nbPages,
      totalNumberOfItems: results?.nbHits,
      itemsPerPage: results?.hitsPerPage,
      objectIDs: objectIDs,
    });
  };

  const router = useRouter();
  const currentPath = getCurrentPath(router?.asPath);
  const queryString = getQueryString(currentPath);
  const locale = _.get(qs.parse(queryString), 'locale', undefined) as string;
  const currentPathname = getCurrentPathname(router?.asPath);

  return (
    <>
      <InstantSearch
        onSearchStateChange={onSearchStateChange}
        searchState={searchState}
        searchClient={searchClient}
        resultsState={resultsState}
        indexName={indexName}
        {...props}
      >
        <AlgoliaConnectAnalytics
          collectionTitle={collectionTitle}
          indexName={indexName}
          language={language}
          locale={locale}
          pathName={currentPathname}
          allFacetsFromAlgolia={allFacetsFromAlgolia}
        />

        <Configure
          clickAnalytics={appConfig.algolia.clickAnalytics()}
          filters={filters}
          tagFilters={_tags}
          facets={getFacets(filters)}
          ruleContexts={[ruleContext]}
        />
        {collectionTitle &&
          media &&
          (heroDescription ? (
            <div className={style.heroImage}>
              <CollectionHeader
                title={collectionTitle}
                description={heroDescription}
                setImageExists={setImageExists}
                media={media}
              />
            </div>
          ) : (
            <div className={style.heroImage}>
              <HeroImage
                title={collectionTitle}
                setImageExists={setImageExists}
                media={media}
              />
            </div>
          ))}
        {collection && collection.merchandisingTiles.length > 2 && (
          <div
            className={`gridFreeContainer ${style.featuredLinksWrapper} ${
              collection.merchandisingTiles.length < 5 ? style.extraWrapper : ''
            }`}
          >
            {/* if the hero image does not exist we want to show the heading tag */}
            {!imageExists && collection.merchandisingTiles.length > 2 && (
              <CollectionHeader
                title={collectionTitle}
                description={heroDescription}
                setImageExists={setImageExists}
                media={media}
              />
            )}
            <FeaturedLinks
              links={getFeaturedLinksFromTiles(collection.merchandisingTiles)}
              collectionTitle={collectionTitle}
              language={language}
            />
          </div>
        )}
        {path && (
          <div className={`gridFreeContainer ${style.hierarchyContainer}`}>
            <Hierarchy levels={hierarchy} />
          </div>
        )}
        <div className={`gridFreeContainer ${style.toolbarWrapper}`}>
          <div className={style.toolbar}>
            <div className={style.categoryInfo}>
              <div
                className={`${
                  imageExists ? style.hiddenSmallVisibleLarge : ''
                }`}
              >
                {/* if the hero image exist we don't want to duplicate the heading tag */}
                {!imageExists &&
                  collection &&
                  collection.merchandisingTiles.length < 2 && (
                    <Headline32Regular>{collectionTitle}</Headline32Regular>
                  )}
              </div>
              <div className={style.searchBoxMobile}>
                <SearchBox collectionTitle={collectionTitle} />
                <div className={style.searchBoxOtherButtonsMobile}>
                  <div className={style.currencyButtonMobile}>
                    <CurrencyConverter location={'browse'} />
                  </div>
                  <button
                    className={style.filtersBtn}
                    onClick={() => {
                      if (
                        sidebarVisibility ===
                          SidebarVisibility.VISIBLE_DESKTOP ||
                        sidebarVisibility ===
                          SidebarVisibility.HIDDEN_MOBILE_DESKTOP
                      ) {
                        setSidebarVisibility(
                          SidebarVisibility.VISIBLE_MOBILE_DESKTOP,
                        );
                      } else {
                        setSidebarVisibility(
                          SidebarVisibility.HIDDEN_MOBILE_DESKTOP,
                        );
                      }
                    }}
                  >
                    <Button14Medium>Filters</Button14Medium>
                    <img src={Filter} alt="" />
                  </button>
                  <CollectionViewSwitcher
                    showButtons={true}
                    isGridView={isGridView}
                    setIsGridView={(isGridView: boolean) =>
                      setIsGridView(() => isGridView)
                    }
                    isMobile={true}
                  />
                </div>
              </div>
              <div aria-live="polite" className={style.statsWrapper}>
                <Stats
                  translations={{
                    stats(nbHits) {
                      return `${nbHits} results`;
                    },
                  }}
                />
              </div>
              <div className={style.vatInfoMobile}>
                <VatInfoLink />
              </div>
            </div>
            <div className={style.rightToolbar}>
              <div className={style.sorter}>
                <div className={style.currencyButtonDesktop}>
                  <CurrencyConverter location={'browse'} />
                </div>
                <button
                  className={style.filtersBtn}
                  onClick={() => {
                    toggleSidebarVisibility();
                  }}
                >
                  <Button14Medium>
                    {sidebarVisibility === SidebarVisibility.VISIBLE_DESKTOP ||
                    sidebarVisibility ===
                      SidebarVisibility.VISIBLE_MOBILE_DESKTOP
                      ? 'Hide filters'
                      : 'Show filters'}
                  </Button14Medium>
                  <img src={Filter} alt="" />
                </button>
                <SortBy type="dropdown" trackSortApplied={trackSortApplied} />
                <CollectionViewSwitcher
                  showButtons={true}
                  isGridView={isGridView}
                  setIsGridView={(isGridView: boolean) =>
                    setIsGridView(() => isGridView)
                  }
                />
              </div>
              <div className={style.vatInfoDesktop}>
                <VatInfoLink />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`gridContainer ${
            style.container
          } ${getSidebarClassName()}`}
        >
          <div
            ref={sidebarRef}
            className={`gridSidebar ${style.sidebar}`}
            aria-modal={
              sidebarVisibility === SidebarVisibility.VISIBLE_MOBILE_DESKTOP
                ? true
                : undefined
            }
            role={
              sidebarVisibility === SidebarVisibility.VISIBLE_MOBILE_DESKTOP
                ? 'dialog'
                : undefined
            }
            aria-labelledby={
              sidebarVisibility === SidebarVisibility.VISIBLE_MOBILE_DESKTOP
                ? 'sidebarTitle'
                : undefined
            }
          >
            <div className={style.filtersTitle}>
              <Headline32Regular id={'sidebarTitle'} hTag="h2">
                Filters
              </Headline32Regular>
              <div
                tabIndex={0}
                role="button"
                className={style.closeWrapper}
                onClick={() => {
                  setSidebarVisibility(SidebarVisibility.VISIBLE_DESKTOP);
                }}
                onKeyDown={(e) => {
                  if (e.key == 'Enter' || e.key == ' ') {
                    setSidebarVisibility(SidebarVisibility.VISIBLE_DESKTOP);
                  }
                }}
              >
                <img src={Close} alt="Close Filter controls" />
              </div>
            </div>
            <div className={style.searchBoxWrapper}>
              <SearchBox collectionTitle={collectionTitle} />
            </div>
            <Filters
              refinements={refinements}
              sidebarRef={sidebarRef}
              trackSortApplied={trackSortApplied}
            />
          </div>
          {sidebarVisibility === SidebarVisibility.VISIBLE_MOBILE_DESKTOP && (
            <div
              className={style.sideBarBackdrop}
              onClick={() => {
                setSidebarVisibility(SidebarVisibility.VISIBLE_DESKTOP);
              }}
            ></div>
          )}
          <div
            {...handlers}
            aria-hidden={
              sidebarVisibility === SidebarVisibility.VISIBLE_MOBILE_DESKTOP
            }
          >
            <ScrollTo
              executeAfterScroll={() => {
                focusContentContainerFunctionRef?.current.cb();
              }}
            >
              <CustomHits
                isGridView={isGridView}
                callbackFocusFunction={(cb) => {
                  focusContentContainerFunctionRef.current.cb = cb;
                }}
                authState={authState}
                setShowLoginModal={setShowLoginModal}
                collectionSlug={collectionSlug}
                host={host}
                path={path}
                language={language}
                locale={locale}
                currentPathname={currentPathname}
              />
            </ScrollTo>
          </div>
          {collectionSlug === 'wine' && (
            <div className={style.alcoholWarning}>
              <Paragraph14Regular>
                All alcoholic beverage sales in New York are made solely by
                Sotheby’s Wine (NEW L1046028)
              </Paragraph14Regular>
            </div>
          )}
          <Pagination />
        </div>
      </InstantSearch>
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        login={() => AuthZeroStore.login()}
        register={() => AuthZeroStore.register()}
      />
    </>
  );
};

export default BrowsePage;
