import React, { useRef } from 'react';
import style from './index.module.css';
import Rheostat, { PublicState } from 'rheostat';
import ProgressBar from './progress_bar';
import Background from './background';

type Props = {
  onChange?: (values: PublicState) => void;
  onValuesUpdated?: (values: PublicState) => void;
  min: number;
  max: number;
  sliderPosLow: number;
  sliderPosHigh: number;
  orientation?: 'horizontal' | 'vertical';
  precision?: number;
  currentRefinement: {
    min: number;
    max: number;
  };
};

function toFixedIfNecessary(value: number, dp: number) {
  return +parseFloat(value.toString()).toFixed(dp);
}

export const RangeSlider: React.FunctionComponent<Props> = ({
  onChange,
  onValuesUpdated,
  min,
  max,
  sliderPosLow = 0,
  sliderPosHigh = 0,
  orientation,
  precision = 2,
  currentRefinement,
}) => {
  const isActive = sliderPosLow !== min || sliderPosHigh !== max;
  const isDisabled = min === max;
  const refLow = useRef<HTMLSpanElement>(null);
  const refHigh = useRef<HTMLSpanElement>(null);

  return (
    <div
      className={`${style.rheostatcontainer} ${style.filterContainer} ${
        isActive ? 'active' : ''
      } ${isDisabled ? 'disabled' : ''}`}
    >
      <div
        className={`${style.sliderWrapper} ${isDisabled ? style.disabled : ''}`}
      >
        <Rheostat
          min={min}
          max={max}
          values={[currentRefinement.min, currentRefinement.max]}
          onChange={onChange}
          disabled={isDisabled}
          progressBar={({ style }) =>
            ProgressBar({ style, isActive, isDisabled, orientation })
          }
          onValuesUpdated={onValuesUpdated}
          background={(props) => Background(props)}
        />
      </div>
      <div className={style.sliderValues}>
        <span ref={refLow}>
          {precision
            ? toFixedIfNecessary(sliderPosLow, precision)
            : sliderPosLow}
        </span>
        <span ref={refHigh}>
          {precision
            ? toFixedIfNecessary(sliderPosHigh, precision)
            : sliderPosHigh}
        </span>
      </div>
    </div>
  );
};

export default RangeSlider;
